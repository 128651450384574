<template>
      <div>
          <v-list class="ma-auto price-tag">
            <p v-if="theme" class="smallTxt wi-100 pl-5 ma-0"><em>{{theme}}</em></p>
            <v-list-item class="align-start d-flex">
              <v-list-item-content class="text-uppercase wi-70">
                <strong class="left">{{ name }}</strong>
              </v-list-item-content>
              <v-list-item-action  class="right wi-30">
                <strong>{{totalPrice}} {{currency}}</strong>
                <em v-if="installments && installment > 0 && !recover" class="tinyTxt">{{installment}} {{wording.priceTag.installment}} <br> <strong>{{monthPrice}}{{currency}} / {{wording.basics.month}}</strong></em>
                <em v-else-if="installments && installment > 0 && recover" class="tinyTxt">{{wording.priceTag.duration}} <strong>{{installment}} {{wording.basics.month}} </strong></em>
                <em v-else class="tinyTxt">{{wording.priceTag.instantCharge}}</em>
              </v-list-item-action>

            </v-list-item>
            <v-expand-transition>
              <div  v-if="paysuccess"  >
                <v-list-item class="align-start d-flex flex-column flex-md-row pt-5">
                  <v-list-item-content class="text-uppercase flex1"><strong class="left">+ {{wording.priceTag.successPart}}</strong></v-list-item-content>
                  <v-list-item-action  class="right flex0">
                    <strong>{{successPrice}} {{currency}}</strong>
                    <em class="tinyTxt">{{wording.priceTag.successPartExplain}}</em>
                  </v-list-item-action>
  
                </v-list-item>
              </div>
            </v-expand-transition>
          </v-list>
      </div>
</template>

<script>
export default {
    name: 'price-tag',
    props:['wording', 'price','installment','installments','name','theme','paysuccess','successRate','recover','currency'],
    mounted(){
    },
    methods:{
    },
    computed: {
      totalPrice(){
        return this.paysuccess ? this.price * this.successRate : this.price;
      },
      monthPrice(){
        return Math.floor((this.totalPrice / this.installment)*100)/100;
      },
      successPrice () {
        return this.price * (1 - this.successRate)
      }
  }
}
</script>

<style lang="scss">
.price-tag {
  background: rgba(211, 211, 211, 0.5)!important;
  border-radius: 0.5rem!important;
}
</style>