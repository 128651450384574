export const prodPlans = [
    { 
      countries:['fr'],
      text:'Bilan intensif - 1490€',price:1490,id:4,
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1Ho4x2AappgCDnXwb4lrJVIB',
          three_months: 'price_1NcoMSAappgCDnXw1mLXx0vk',
          five_months: 'price_1Ho4x2AappgCDnXwjouGPAfe'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    { 
      countries:['fr'],
      text:'Coaching complet - 2050€',price:2050,id:1,
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1HcsT8AappgCDnXwwaSJHJ9c',
          three_months: 'price_1NcoQAAappgCDnXwtutN6uwu',
          five_months: 'price_1HcsT8AappgCDnXwh9wd9d6S'
        },
        successRate: 0.6,
        success:{
          default:'price_1HcsT8AappgCDnXwAUpLGnJR',
          three_months:'price_1HcsT8AappgCDnXwh9wd9d6S',
          five_months:'price_1HcsT8AappgCDnXwPrEyXizN'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    { 
      countries:['fr'],
      text:'Bilan + Coaching complet - 2450€',price:2450,id:2,
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1HcsRPAappgCDnXw4Qbxft4Q',
          three_months: 'price_1NcoYdAappgCDnXwS2DGNONu',
          five_months: 'price_1HcsRPAappgCDnXweYlBeX5E'
        },
        successRate: 0.6,
        success:{
          default:'price_1HcsRPAappgCDnXwSrKB3I01',
          three_months: 'price_1HcsRPAappgCDnXweYlBeX5E',
          five_months:'price_1HcsRPAappgCDnXwUfYu1TqK'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    { 
      countries:['fr'],
      text:'Bilan Reconversion - 2850€',price:2850,id:6,
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1IzdLcAappgCDnXwZi5EX2bg',
          three_months: 'price_1NcoaJAappgCDnXw2N37aVT3',
          five_months: 'price_1IzdLcAappgCDnXwEFHcAXTt'
        },
        successRate: 0.6,
        success:{
          default:'price_1IzdObAappgCDnXwXimPJxCT',
          three_months:'price_1IzdLcAappgCDnXwEFHcAXTt',
          five_months:'price_1IzdP4AappgCDnXwDELMnnBz'
        },
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    {
      countries:['ch'],
      text:'Outils et entretiens - 1000CHF',price:1000, currency:"CHF",id:"4-swiss",
      possible_installments: [3],
      plans:{
        full:{
          default: 'price_1JUsGZAappgCDnXwYy5qmXdf',
          three_months: 'price_1NcogCAappgCDnXwrTPQIVwA'
        }
      }
    },
    {
      countries:['ch'],
      text:'Bilan intensif - 1850CHF',price:1850, currency:"CHF",id:"6-swiss",
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1LDq0NAappgCDnXwZ1RYWjJp',
          three_months: 'price_1Ncoh9AappgCDnXwtz2HAbgJ',
          five_months: 'price_1LDq0NAappgCDnXwpEc3nHjS'
        }
      }
    },
    { 
      countries:['ch'],
      text:'Coaching complet - 2450CHF',price:2450, currency:"CHF",id:"1-swiss",
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1JUsGgAappgCDnXwykvKeg6O',
          three_months: 'price_1NcoieAappgCDnXw72SyYl2S',
          five_months: 'price_1JUsGfAappgCDnXwqzEKWlrW'
        },
        successRate: 0.6,
        success:{
          default:'price_1JUsGfAappgCDnXwZfGFCNnq',
          three_months: 'price_1JUsGfAappgCDnXwqzEKWlrW',
          five_months:'price_1JUsGfAappgCDnXwHNChtqby'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    {
      countries:['ch'],
      text:'Bilan + Coaching complet - 2850CHF',price:2850, currency:"CHF",id:"2-swiss",
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1JUsGmAappgCDnXwFqwfgZm7',
          three_months: 'price_1NcokUAappgCDnXwi3tYVnNG',
          five_months: 'price_1JUsGlAappgCDnXw0E7cf7Gs'
        },
        successRate: 0.6,
        success:{
          default:'price_1JUsGlAappgCDnXwW3s5EAfh',
          three_months:'price_1JUsGlAappgCDnXw0E7cf7Gs',
          five_months:'price_1JUsGlAappgCDnXwW3s5EAfh'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c' 
    },
    {
      countries:['en'],
      text:'Getting ready coaching - 1500 $US',price:1500, currency:"$US",id:"1-en",
      possible_installments: [5],
      plans:{
        full:{
          default: 'price_1LDqMyAappgCDnXwc9LmhhiL',
          five_months: 'price_1LDqMyAappgCDnXwBOWzf62n'
        },
        successRate: 0.5,
        success:{
          default:'price_1LDqMyAappgCDnXwc0CkbBIF',
          five_months:'price_1LDqMyAappgCDnXwjQoK1UaM'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    {
      countries:['en'],
      text:'Getting hired coaching - 3700 $US',price:3700, currency:"$US",id:"2-en",
      possible_installments: [5],
      plans:{
        full:{
          default: 'price_1LDqOgAappgCDnXwI6TIi1jV',
          five_months: 'price_1LDqOgAappgCDnXw29jl8DXA'
        },
        successRate: 0.5,
        success:{
          default:'price_1LDqOgAappgCDnXwYkXQruA9',
          five_months:'price_1LDqOgAappgCDnXw7nPzI4XA'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    {
      countries:['en'],
      text:'Executive coaching - 5000 $US',price:5000, currency:"$US",id:"3-en",
      possible_installments: [5],
      plans:{
        full:{
          default: 'price_1LDqPOAappgCDnXwbSjIxiDC',
          five_months: 'price_1LDqPOAappgCDnXwyqxJIKcE'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    {
      countries:['en'],
      text:'Interview Coaching - 1500 $US',price:1500, currency:"$US",id:"4-en",
      possible_installments: [5],
      plans:{
        full:{
          default: 'price_1LDqQAAappgCDnXwJ6jEyN7j',
          five_months: 'price_1LDqQAAappgCDnXws57A69k9'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    {
      countries:['en'],
      text:'Find a job in Europe coaching - 5300 $US',price:5300, currency:"$US",id:"5-en",
      possible_installments: [5],
      plans:{
        full:{
          default: 'price_1LDqRyAappgCDnXwtCPXFkmL',
          five_months: 'price_1LDqRyAappgCDnXwJElsuS16'
        },
        successRate: 0.5,
        success:{
          default:'price_1LDqRyAappgCDnXwX79rcvZq',
          five_months:'price_1LDqRyAappgCDnXwSGU7lODN'
        }
      },
      journey: '639300cd440b4038ebda948f 63932ab5440b4038ebda9719 63932f7e440b4038ebda9943 631f23e67d4adb1250f5ad4c'
    },
    { 
      countries:['fr'],
      text:'E-learning MachinaJOB',id:5
    },
    { 
      countries:['fr'],
      text:'Un atelier thématique',id:3
    }
  ];

export const prodWorkshops = {
    interview :[
      {
        text:`1h de préparation flash pour un entretien à venir - 149€ `,price:149,id:1,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HcsT4AappgCDnXwc0S18VMe'
          }
        }
      },
      {
        text:`2h d'intense préparation - 330€ `,price: 330,id:2,
        possible_installments: [2],
        plans:{
          full:{
            default: 'price_1HcsT0AappgCDnXwiPRAy58H',
            two_months: 'price_1NcomjAappgCDnXwkpeTwgoL'
          }
        }
      },
      {
        text:`4h des méthodes + des simulations - 660€`,price: 660,id:3,
        possible_installments: [3],
        plans:{
          full:{
            default: 'price_1HcsStAappgCDnXw8IMB1z0z',
            three_months:'price_1HcsStAappgCDnXwMAH2Hpoh'
          }
        }
      }
    ],
    network:[
      {
        text:`2h de coaching + travail personnel encadré - 330€`,price: 330,id:1,
        possible_installments: [2],
        plans:{
          full:{
            default: 'price_1HcsSnAappgCDnXwLWcbm8sN',
            two_months:'price_1NcooKAappgCDnXw8crH778T'
          }
        }
      }, 
      {
        text:`4h de méthodes + refonte des outils et compte LinkedIn - 660€`,price: 660,id:2,
        possible_installments: [3],
        plans:{
          full:{
            default: 'price_1HcsSdAappgCDnXwYezcQcNN',
            three_months:'price_1HcsSdAappgCDnXwlyYzjMgP'
          }
        }
      }            
    ],
    tools:[
      {
        text:`1h pour revoir le CV vite et bien - 149€`,price: 149,id:1,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HcsSYAappgCDnXwJR8wyeNj'
          }
        }
      },  
      {
        text:`2h pour revoir tous vos outils - 330€`,price: 330,id:2,
        possible_installments: [2],
        plans:{
          full:{
            default: 'price_1HcsSSAappgCDnXwfkMdx9Y0',
            two_months:'price_1Ncop1AappgCDnXw9IlJxBlf'
          }
        }
      } ,
      {
        text:`4h outils et stratégie de candidature - 660€`,price: 660,id:3,
        possible_installments: [3],
        plans:{
          full:{
            default: 'price_1I8joEAappgCDnXw1AMZb2KN',
            three_months:'price_1I8joEAappgCDnXwG2N4S2Ml'
          }
        }
      }         
    ],
    assessment:[
      {
        text:`1h pour réfléchir à vos choix de carrière - 149€`,price: 149,id:1,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HcsSNAappgCDnXwVkXI6pq4'
          }
        }
      },
      {
        text:`2h pour faire le bilan complet - 250€`,price: 250,id:2,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HcsSIAappgCDnXwBHckibQp'
          }
        }
      }   
    ]
  }

export const prodElearning = [
    {
      countries: ['fr'],
      text:`MyJobAcademy illimité - 449€`,price:490,id:1,
      possible_installments: [],
      plans:{
        full:{
          default: 'price_1JtUdBAappgCDnXwm0OCk5GC'
        }
      }
    },
    {
      countries: ['fr'],
      text:`MyJobAcademy illimité + 2h de coaching - 660€`,price:660,id:2,
      possible_installments: [],
      plans:{
        full:{
          default: 'price_1JtUd5AappgCDnXw3Hg8KFQI'
        }
      }
    }
  ]