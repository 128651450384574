import Vue from 'vue';
  
  const state = {
    display: false,
    content: {
        title: '',
        message:'',
        button:'',
        alt: ''
    },
    cb: ()=>{
      console.log('Im a callback')
    },
    altCb: ()=>{
      console.log('Im an alt callback')
    }
  }
  
  
  const actions = {
    open_dialog: (context,payload)=>{
        context.commit('open_dialog',payload);
    },
    close_dialog: context=>{
        context.commit('close_dialog');
    }
  };
  
  const mutations = {
    open_dialog: (state,payload)=>{
        const dialog ={
            content:{
                title: payload.title || '',
                message: payload.message || '',
                button: payload.button || 'Fermer',
                alt: payload.alt || ''
            },
            cb: payload.cb || '',
            altCb: payload.altCb || ''
        }
        Vue.set(state,'display',true);
        Vue.set(state,'content',dialog.content);
        Vue.set(state,'cb',dialog.cb);
        Vue.set(state,'altCb',dialog.altCb);
    },
    close_dialog: state=>{
        Vue.set(state,'display',false);
    }
  };
  
  export default {
    state,
    actions,
    mutations
  };
  