import Vue from "vue"
import Vuex from "vuex"
import user from "@/assets/jobtimise-common/store/modules/user"
import auth from "@/assets/jobtimise-common/store/modules/auth"
import register from "@/assets/jobtimise-common/store/modules/register"
import dialog from "@/assets/jobtimise-common/store/modules/dialog"
import popup from "@/assets/jobtimise-common/store/modules/popup"
import status from "@/assets/jobtimise-common/store/modules/status"
import loginDialog from "@/assets/jobtimise-common/store/modules/loginDialog"
import messageInput from '@/assets/jobtimise-common/store/modules/messageInput'
import navigation from '@/assets/jobtimise-common/store/modules/navigation'
import bannerInfos from '@/assets/jobtimise-common/store/modules/bannerInfos'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    auth,
    register,
    dialog,
    popup,
    status,
    loginDialog,
    messageInput,
    navigation,
    bannerInfos
  },
  state:{
  },
  mutations:{
  },
  actions:{
  }
})
