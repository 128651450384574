<template>
  <div class="chat-engine" ref="chat">
        <span v-for="(message, index) in cachedMessages" :key="index" :class='[message.author, message.type]'>
            <chat-start v-if="message.type === 'start'" @done="handleNextMessage()"></chat-start>
            <reset-btn v-else-if="message.type === 'next'" :cb="message.cb" @clear="clearMessage()" :text="message.text"></reset-btn>
            <message v-else 
                @done="handleNextMessage($event)" 
                :user="shellObject" 
                :content="message.content" 
                :author="message.author" 
                :optional="message.optional"
                :state="index + counterStart === counter - 1 ? 'active' : 'done'"
            ></message>
        </span>
        <message-input v-if="$store.state.messageInput.display"></message-input>
    </div>
</template>

<script>
import ResetBtn from './ResetBtn.vue'
import message from './Message.vue'
import ChatStart from './Start.vue'
import MessageInput from './inputs/MessageInput.vue'
import Vue from 'vue'

export default {
    name : 'chat-engine',
    components: { message, MessageInput, ResetBtn, ChatStart },
    props: ['scenario','shellObject'],
    data () {
        return {
            counterStart : 0,
            counter: 0
        }
    },
    created () {
        setTimeout(() => {
            this.counter = 1
        }, 1000);
    },
    methods : {
        handleNextMessage (payload) {
            this.handlePayload(payload)
            if(this.counter === this.scenarioScript.length){
                this.$emit('done')
            }
            if(typeof this.scenario.update === 'function') this.scenario.update()
            this.counter++
            if(  this.counter - this.counterStart > 0 ){
                setTimeout(() => {
                    this.$refs.chat.scrollTop = this.counter * 500
                    window.scrollTo(0,this.counter * 500)
                }, 10);
            }
        },
        handlePayload (payload) {
            for( const key in payload){
                if (key === 'qualities') Vue.set(this.shellObject, key, [ ...this.shellObject.qualities, ...payload[key] ]) 
                else Vue.set(this.shellObject, key, payload[key])
                if (key === 'rankedQualities') this.$emit('ranked')
            }
        },
        clearMessage () {
            this.$emit('next')
            this.counterStart = this.counter
            window.scrollTo(0,0)
            setTimeout(()=>{
                this.handleNextMessage()
            },10)
        }
    },
    computed : {
        cachedMessages () {
            return this.scenarioScript.slice(this.counterStart,this.counter)
        },
        scenarioScript () {
            return this.scenario.script()
        }
    }
}
</script>

<style lang="scss">
.chat-engine{
    @include noScrollbar();
    position: relative;
    scroll-behavior: smooth;
    width: 60%;
    margin: auto;
    margin-top: 0;
    flex-grow: 1;
    overflow: auto;
    @include chatContainer();
    @include phone{
        width: 99%;
        padding: 6rem 0.5rem 5rem;
    }
    >span{
        display: block;
        margin-top: 2rem;
        &:first-child{
            margin-top: 0;
        }
        &.self + .self{
            margin-top: 0.5rem;
        }
        &.sender + .sender {
            margin-top : 0.5rem;
        }
        &.start{
            + span {
                margin-top: 0;
            }
        }
    }
    .text-gradient{
        font-size: 1.2em;
        @include phone{
            font-size: 1.1em;
        }
    }
}

</style>