<template>
  <div class="chat-steps">
      <span v-for="(step, index) in steps" :key="index" :class="{ active: index === activeStep }">{{index + 1}}</span>
      <p>{{steps[activeStep].step}}</p>
  </div>
</template>

<script>
export default {
    name: 'chat-steps',
    props: ['scenario','activeStep'],
    computed : {
        steps () {
            return  this.scenario ? this.scenario.filter(e => {return ['start', 'next'].includes(e.type) }) : []
        }
    }
}
</script>

<style lang="scss">
    .chat-steps{
        background: rgba(white, 0.9);
        box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
        border-bottom-left-radius: 10px ;
        border-bottom-right-radius: 10px;
        padding: 1em 1em 0.5em;
        &:after{
            content: "";
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: white;
            transition: all ease 0.3s;
        }
        &.disabled{
            &:after{
                content: "";
                opacity: 0.9;
            }
        }
        >span{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $textGrey;
            font-size: 0.7em;
            font-weight: 900;
            border: $textGrey solid 0.2em;
            box-sizing: border-box;
            border-radius: 100%;
            width: 2.2em;
            height: 2.2em;
            margin-right: 2.5rem;
            position: relative;
            transition: all ease 0.3s;
            &.active{
                background: $textGrey;
                color: white;
            }
            &:after{
                content: '';
                display: block;
                position: absolute;
                left: calc(100% + 1px);
                top: 50%;
                width: 2.6rem;
                border-top: $textGrey 3px dotted;
                transform: translate(0, -50%);
            }
            &:nth-last-child(2){
                margin-right: 0;
                &:after{
                    display: none;
                }
            }
        }
        p{
            color: $textGrey;
            margin-top: 0.3em;
            margin-bottom: 0;
        }
    }
</style>