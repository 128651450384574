<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height align-start pa-0" fluid>
        <transition name="fade">
          <router-view class="flex-grow-1" :key="$route.fullPath" />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "app",
  components: {
  },
  data:()=>{
    return {
      drawer: false
    }
  },
  created: function() {
    document.title = this.$route.meta.title || 'Jobtimise Elearning'
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Jobtimise Elearning'
    }
  },
  computed: {
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@import 'assets/jobtimise-common/scss/style.scss';
@import 'assets/scss/pay.scss';

#inspire {
  font-family: $titleFont, Helvetica, Arial, sans-serif;
}
</style>
