export const frLegalNotice = `
CONDITIONS GÉNÉRALES D'INSCRIPTION FRANCE <br><br><br>
Préambule <br><br>
Unique Corn SARL ( ci-après : « la Société ») est une société active dans le domaine de l’orientation professionnelle et scolaire, la formation professionnelle, l’accompagnement et le coaching professionnel et le conseil en ressources humaines. <br>
Les présentes Conditions Générales de Services gouvernent le fonctionnement de cas aussi différents que ceux de cadres dirigeants, ouvriers spécialisés, cadres supérieurs,  stagiaires, ingénieurs de haut niveau ou  débutants.   <br>
De manière générale, les relations entre la Société et les bénéficiaires de ses prestations sont régies par les principes de la bonne foi. Unique Corn Sàrl s’efforce de faire preuve de souplesse et de compréhension. <br>
Cependant, faute d’accord expresse écrit contraire de La Société, seules les présentes conditions gouvernent la relation de service.
<br><br><br>
1. Définition<br><br>
« Jobtimise » est une marque de la Unique Corn Sàrl domiciliée : Rue du Petit Chêne 22, C/O Gaapex SARL, 1003 Lausanne - Suisse TVA : CHE-494.233.370.<br>
«Contrat » comprend les présentes Conditions Générales de Services, le descriptif et la tarification de la formule choisie ainsi que les éventuels amendements écrits aux Conditions Générales de Services.<br>
« Souscripteur » : le bénéficiaire des prestations d’accompagnement professionnel. <br>
« Période d’accompagnement » : période d’accompagnement et de coaching effective définie selon la formule d’accompagnement choisie par le Souscripteur.<br>
« Période de transformation » : période d’accompagnement subséquente à période d’accompagnement définie selon la formule d’accompagnement choisie.<br>
« Montant fixe forfaitaire » : il s’agit du montant forfaitaire dû par le Souscripteur au début de l’accompagnement ouvrant l’accès aux prestations de la Société.<br>
« Prime au succès » : il s’agit du montant à payer à la fin de la Période de transition en cas de réussite de l’accompagnement.<br>
<br><br><br>
2. Objet<br><br>
1. Les présentes Conditions Générales de Services visent à définir les relations contractuelles entre la Société et le Souscripteur. Ces conditions sont applicables à l’ensemble des prestations proposées par la Société au travers de son site Internet et au travers d’une promotion Internet. <br>
2. Sauf, accord expresse contraire écrit, la souscription à toute offre proposée par la Société implique une acceptation sans réserve par le Souscripteur des présentes Conditions Générales de Services.

<br><br><br>
3. Modalités d’inscription<br><br>
1. Le Souscripteur doit obligatoirement s’inscrire en remplissant en ligne le formulaire d’inscription destiné à cet effet. Aucune préinscription ne peut être effectuée par téléphone ou fax. Le formulaire peut être le cas échéant renvoyé par email en format numérique (format .pdf signé ou scan de la copie originale signée manuellement). Dans un délai de huit jours à compter de cette préinscription, le Souscripteur recevra par courrier électronique les présentes Conditions Générales de Services, le descriptif de la prestation réclamée, ainsi que sa tarification. <br>
2. L’inscription ne sera réputée définitive qu’à compter de la réception du paiement du Montant fixe forfaitaire par le Souscripteur lui-même, ou par des tiers effectuant le paiement, en précisant que celui-ci sert à s’acquitter des frais d’inscription du Souscripteur.<br>
3. En cas d’étalement de paiement du Montant fixe forfaitaire, l’inscription est réputée définitive lors  de la réception du premier versement. <br>
4. Le Souscripteur s’engage lors de son inscription à se rendre disponible pour effectuer l’intégralité des séances de coaching lui étant attribuées dans un délai de 6 mois à compter de l’inscription.
<br><br><br>
4. Rémunération de la Société <br><br>
1. Sauf mention expresse contraire, les services de la Société sont rémunérés au travers : <br>
a. d’un Montant fixe forfaitaire généralement payé lors de l’inscription du Souscripteur et ; <br>
b.d’une Prime au succès qui doit être versée suite à la réalisation d’un événement constitutif de la réussite de l’accompagnement. <br>
Exemple : formule complète coaching = paiement “fixe” de CHF 899.- + Prime au succès de CHF 1’151.-, prix total de CHF 2’050.- <br>
2. Dans le cadre d’une formule soumise à une Prime au succès, le Souscripteur s’engage à régler ladite Prime dès l’intervention de l'événement caractérisant ledit succès, comme décrit ci-après dans l'article 6. Si cet évènement intervient avant l’épuisement de l’intégralité des séances de coaching, Jobtimise s’engage à proposer des séances alternatives en même nombre ou fournir au Souscripteur un avoir du nombre de séances restantes valable 24 mois. La Prime au succès sera due cependant sans exception à l'article 6.
<br><br><br>
5. Modalité de paiement du Montant fixe forfaitaire<br><br>
1. Sauf exceptions prévues aux articles 12 et 13, le Montant fixe forfaitaire est dû dans son entier par le Souscripteur indépendamment d’une résiliation du contrat par le Souscripteur et nonobstant un étalement du paiement du Montant fixe forfaitaire.
<br><br><br>
6. Exigibilité et modalité de paiement de la Prime au succès<br><br>
1. La Prime au succès est due dans les 30 jours qui suivent la réussite de l’accompagnement. Le Souscripteur a le devoir d’informer la Société de la réussite de l’accompagnement dès qu’il est réalisé.  <br>
2. Est considéré comme accompagnement réussi si, à la fin de la Période de transition, le Souscripteur a alternativement :<br>
- une proposition d’emploi qu’il décide d’accepter ; <br>
- une promotion dans son entreprise ou une revalorisation de salaire significative de plus de 10% ;<br>
- décidé de créer son entreprise seul ou avec des associés ;<br>
- rejoint une entreprise en tant qu’associé.<br>
3. A l’issue de la Période de transformations, le Souscripteur transmettra à la Société par lettre Recommandée avec Accusé de Réception, toutes pièces permettant de démontrer que sa situation professionnelle n’a pas changé et qu’aucun événement constitutif d’un accompagnement réussi n’a eu lieu. En particulier, un Souscripteur demeuré sans emploi transmettra une attestation d’inscription à un service public des demandeurs d’emploi datée du mois en cours. A défaut de cet envoi dans les 2 mois suivants la fin de la Période de transformations, le Souscripteur devra dans tout les cas s’acquitter de la Prime au succès.
<br><br><br>
7. Tarification<br><br>
1. Les Tarifs transmis par courrier électronique par la Société au Souscripteur sont valides pour une durée de 30 jours faisant suite à la notification dudit courrier électronique. Passé ce délai de validité, ces Tarifs transmis sont valides uniquement s’ils ne dérogent pas à la tarification indiquée sur le site Internet de www.jobtimise.com ou www.jobtomise.ch. <br>
2. Les tarifs indiqués s’entendent hors taxes, sauf mention explicite d’un prix toutes taxes comprises (TTC). <br>
3. La Société se réserve expressément le droit de modifier ses tarifs à tout moment durant l'année. Cependant, dès la souscription à un service de la Société, soit à la réception du ou des paiements,  les tarifs ne peuvent plus être modifiés par la Société et cette dernière ne peut demander rétroactivement un montant supplémentaire pour quelque motif que ce soit. <br>
4. Les prix indiqués sur les supports publicitaires ne sont pas contractuels et ne lient pas la Société.

<br><br><br>
8. Méthode de paiement <br><br>
Le souscripteur a la possibilité de payer la Société par paiement en ligne ou par virement bancaire uniquement. 
<br><br><br>
9. Étalement des paiements<br><br>
Le souscripteur peut s’adresser à l’administration de la Société par email afin de demander un échelonnement ou un décalage des paiements. La Société a toute liberté d’accepter ou de refuser une telle requête.  
<br><br><br>
10. Paiement via l’utilisation d’aides publiques<br><br>
En cas de paiement total des formations via des aides publiques, les formations ne peuvent faire l’objet d’une séparation sous la forme d’un paiement d’un Montant fixe forfaitaire  et d’une Prime au succès . Le client paye l’intégralité via les aides obtenues ou en complétant celles-ci. Sauf dans le cas d’un accord expresse écrit de la Société, le paiement n’est pas subordonné au succès de l’accompagnement. 
<br><br><br>
11. Financement des formations par une personne morale<br><br>
En cas de paiement total des formations via une personne morale (entreprise, association, fondation), les formations ne peuvent faire l’objet d’une séparation sous la forme d’un paiement d’un Montant fixe forfaitaire  et d’une Prime au succès . Le client paye l’intégralité via les aides obtenues, mais a tout de même la possibilité de demander un étalement de ces paiements. Sauf dans le cas d’un accord expresse écrit de la Société, le paiement n’est pas subordonné au succès de l’accompagnement. 
<br><br><br>
12. Délai de rétractation<br><br>
Le Souscripteur dispose d’un délai de sept (7) jours francs après le paiement de l’inscription pour exercer son droit de rétractation, et les honoraires versés seront  remboursés dans leur totalité sans que le Souscripteur ait à justifier des motifs de la rétractation. <br>
Le droit de rétractation de sept (7) jours ne peut être exercé si l'exécution du Contrat a déjà commencé.
<br><br><br>
13. Conditions d’annulation<br><br>
Toute inscription devenue définitive peut faire l’objet d’une annulation sans frais sous réserve qu’aucune prestation n’ait déjà été fournie par la Société (incluse, la participation à des sessions de formation collectives ou individuelles, l’accès aux modules d’eLearning, l’envoi des « Road Books », les séances de coaching, les échanges téléphoniques avec les formateurs).Cette annulation doit être portée à la connaissance de la Société par Lettre Recommandée avec Accusé de Réception au minimum cinq (5) jours avant la date de début de la formation. <br>
Des par la Société au Souscripteur, le Montant fixe forfaitaire est dû nonobstant la part des prestations effectivement fournies. Cependant, en cas de résiliation motivée par la force majeure ou validée au préalable par l’administration de la Société, le remboursement sera effectué au prorata des prestations déjà fournies. <br>
Même en cas de résiliation, la Prime au succès est due si les éléments constitutifs d’un accompagnement réussi prévu à l’art. 6 des présentes Conditions Générales de Services se sont réalisés dans les 30 jours qui suivent la résiliation du Contrat. 
<br><br><br>
14. Devoir d’information du Souscripteur <br> <br>
Le Souscripteur s’engage à donner toutes les informations nécessaires à la bonne exécution du Contrat par la Société. En particulier, ce dernier s’engage à informer sans retard la Société de tout évènement constitutif d’un accompagnement réussi. 
<br><br><br>
15. Données à caractère personnel<br> <br>
En acceptant le Contrat, le Souscripteur consent à ce que la Société collecte, sur une base légale, des données à caractère personnel le concernant nécessaire à l’exécution du Contrat telle que notamment : nom,  adresse, numéro de téléphone, adresse courriel, Curriculum Vitae, statut professionnel.   <br>
La Société s’engage à ne pas communiquer ces informations à des entreprises tierces sauf accord expresse du Souscripteur. 
<br><br><br>
16.Matériel pédagogique <br><br>
Sauf disposition contraire, tout matériel mis à disposition du Souscripteur dans le cadre des prestations effectuées par la Société demeure la propriété de la Société.
<br><br><br>
17.Propriété intellectuelle<br><br>
Tous les éléments relatifs au contenu des prestations, et relatifs au site internet la Société sont et demeurent la propriété intellectuelle exclusive de la Société. Il est interdit de les reproduire, exploiter, rediffuser ou utiliser à quelque titre que ce soit, même partiellement et sous quelque forme que ce soit, sans l’accord explicite de la Société.
<br><br><br>
18.Règles de bonne conduite<br><br>
Le Souscripteur s’engage au respect des consignes du personnel de l’entreprise, des consultants, des intervenants aux stages, des coachs, des locaux et du matériel mis à leur disposition physiquement ou en ligne, ainsi que les horaires prévus par l’emploi du temps. Tout manquement grave ou répété à ces impératifs peut entraîner l’annulation du Contrat.
<br><br><br>
19.Devoir de diligence de la Société<br><br>
La Société est responsable envers le Souscripteur de la bonne et fidèle exécution du Contrat. La Société déploiera tous les efforts pouvant être raisonnablement demandés d’elle pour aider le Souscripteur à atteindre le but du Contrat conformément à la formule choisie.  <br>
Bien que la Société s’efforcera de mettre en œuvre les efforts pouvant être raisonnablement demandés d’elle, aucun résultat n’est garanti par la Société.
<br><br><br>
20.Échanges et disponibilité des consultants <br><br>
La configuration des coachings Jobtimise et l’utilisation des outils numériques a pour but une réactivité maximale. Saisir les opportunités est impératif, et ce avec les conseils avisés des consultants Jobtimise. Les clients de la Société doivent se servir au maximum de cette aide et de tous les outils, très nombreux, mis à leur disposition.                                    
<br><br><br>
21.Clause de sauvegarde <br><br>
Si une disposition des Conditions Générales de Services est ou devient invalide ou si les Conditions Générales de Services sont incomplètes, les autres clauses du Contrat resteront valides. La disposition invalide est remplacée par une disposition qui intervient alors dans le sens et le but de la disposition invalide en ayant effet juridique. La même règle s'applique à toutes les lacunes contractuelles.
<br><br><br>
22. Voie de droit / juridiction compétente <br><br>
La relation juridique entre la Société et le Souscripteur est régie par le droit suisse à moins qu’une règle de droit impératif ne prévoit le contraire. Sauf règle de droit impératif contraire, les parties conviennent que le tribunal du siège social de la Société sera seul compétent pour entendre de tout litige éventuel. La Société est toutefois en droit d’intenter une action contre l’acheteur devant le tribunal du siège social de ce dernier.
<br><br><br>
23. Effets après la fin de la relation contractuelle <br><br>
Nonobstant la fin de la relation contractuelle, les clauses des articles 15,16 et 17 resteront en vigueur conformément à leurs termes.
`