<template>
 <div>
  <j-page-template>
    <template v-slot:content>
          <transition name="basic-fade">
            <div :class="[country?'bg-'+country:'bg-fr']" :key="country"></div>
          </transition>
          <div v-if="alert" :class="[{active:alert},'alert-dialog']">
            <v-card>
              <v-card-text>
                {{alert_message}}
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="alert = false"
                >
                  {{alert_btn}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <v-container :class="country">
            <v-row class="justify-center align-center d-flex flex-column">
              <v-col cols="12" class="justify-center pa-2 pa-md-10">
                <v-card class="pa-4 pa-md-10 pb-15 wi-50 wi-sm-100 d-flex justify-center flex-column ma-auto">
                  <div class="loader" v-if="$store.state.status.loading || loading">
                      <v-progress-circular
                        size="80"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <p class="center white_">{{loading_message}}</p>
                  </div>
                  <img :src="`/images/logo-${country}.png`" class="wi-60 wi-sm-100 mb-5 ma-auto" alt="">
                  <v-form id="payment-form" class="d-flex flex-column align-items-center" >
                    <v-carousel touchless height="auto"  v-model='carouselIndex' :show-arrows="false" :hide-delimiters="true">
                        <v-carousel-item class="center">
                          <div class="wi-80 wi-sm-100 ma-auto">
                            <p class="ma-auto center mb-5">{{wording.credentials.welcome}}</p>
                            <p class="ma-auto  mb-5"><em>{{wording.credentials.intro}}</em></p>
                            <v-text-field v-model="user.firstname" :label="`${wording.credentials.yourFirstname} *`" hide-details="auto" class=" ma-auto mb-8"></v-text-field>
                            <v-text-field v-model="user.lastname" :label="`${wording.credentials.yourLastname} *`" hide-details="auto" class=" ma-auto mb-8"></v-text-field>
                            <v-text-field type="email" v-model="user.email" :label="`${wording.credentials.yourEmail} *`" :rules="[rules.email]" hide-details="auto" class=" ma-auto mb-8"></v-text-field>
                            <v-text-field v-model="user.phone" :label="`${wording.credentials.yourPhone} *`" hide-details="auto" class=" ma-auto mb-8"></v-text-field>
                            <v-text-field v-model="user.address" :label="`${wording.credentials.yourAddress} *`" hide-details="auto" class=" ma-auto mb-8"></v-text-field>
                            <p class="tinyTxt left mt-10 mb-0"><em>{{wording.credentials.usage}}</em></p>
                            <v-expand-transition>
                              <div v-if="validCredentials" class="wi-100 d-flex justify-center">
                                <v-btn class="btn btn-primary mt-10 mb-5 ma-auto outlined" outlined v-on:click.prevent="firstSlide">{{wording.basics.next}} <v-icon>mdi-arrow-right</v-icon></v-btn>
                              </div>
                            </v-expand-transition>
                          </div>
                        </v-carousel-item>
                        <v-carousel-item class="center">
                          <div class="wi-80 wi-sm-100 ma-auto">
                            <p class="ma-auto mb-5">{{wording.coaching.question}}</p>  
                            <p v-if="country === 'fr'" class="text-left"><em>Toutes nos formations sont <strong>payables en plusieurs fois.</strong> <br> Celles à 660€ ou plus sont <strong>finançables via CPF.</strong></em></p>
                            <p v-if="lang==='en'" class="text-left" v-html="wording.coaching.payable"></p>
                            <v-select
                              :items="selectablePlans"
                              class=" ma-auto mt-5"
                              :label="wording.coaching.yourChoice"
                              v-model="selectedPlan"
                              item-text="text"
                              item-value="id"
                              solo
                              @change="resetOffer"
                              hide-details="auto"
                              ></v-select>
                            <p v-if="selectedPlan == 3" class="ma-auto left mb-5 mt-10">Très bien, quel thème désirez-vous aborder avec nous ?</p>
                            <v-select
                              v-if="selectedPlan == 3"
                              :items="themes"
                              class=" ma-auto mt-5"
                              label="Nos thématiques d'atelier"
                              v-model="selectedTheme"
                              item-text="text"
                              item-value="slug"
                              solo
                              @change="selectedWorkshop = '';resetCPF();"
                              hide-details="auto"
                            ></v-select>
                            <p v-if="selectedPlan == 3 && selectedTheme" class="ma-auto left mb-5 mt-10">Bien noté, quelle formule correspond le mieux à vos besoins?</p>
                            <v-select
                              v-if="selectedPlan == 3 && selectedTheme"
                              :items="workshops[selectedTheme]"
                              class=" ma-auto mt-5"
                              label="Nos thématiques d'atelier"
                              v-model="selectedWorkshop"
                              item-text="text"
                              item-value="id"
                              solo
                              @change="resetCPF()"
                              hide-details="auto"
                            ></v-select>
                            <v-select
                              v-if="selectedPlan == 5"
                              :items="elearning"
                              class=" ma-auto mt-5"
                              label="Nos formules e-learning MachinaJOB"
                              v-model="selectedElearning"
                              item-text="text"
                              item-value="id"
                              solo
                              @change="resetCPF()"
                              hide-details="auto"
                            ></v-select>

                            <p v-if="selectedPlan && selectedPlan !== 3 && possibleInstallments.length || selectedPlan === 3 && selectedTheme && selectedWorkshop" class="ma-auto mb smallTxt center mt-5"><em>{{wording.coaching.payableIn}} 1 {{possibleInstallments.length ? wording.coaching.conditional : ''}} {{payable}} {{wording.coaching.installments}}</em></p>
                            <v-expand-transition >
                              <div v-if="selectedPlan && ![3,5].includes(selectedPlan) || selectedPlan === 3 && selectedTheme && selectedWorkshop || selectedPlan === 5 && selectedElearning" class="wi-100 d-flex justify-center">
                                <v-btn class="btn btn-primary mt-10 ma-auto outlined" outlined v-on:click.prevent="carouselIndex++">{{wording.basics.next}} <v-icon>mdi-arrow-right</v-icon></v-btn>
                              </div>
                            </v-expand-transition>
                            <p class="back smallTxt center mb-0 mt-10" style="cursor:pointer;color:grey;" @click="carouselIndex--">
                              <v-btn class="mr-2" outlined x-small fab color="grey">
                                <v-icon>mdi-arrow-left</v-icon>
                              </v-btn>
                              {{wording.coaching.back}}
                            </p>
                          </div>
                        </v-carousel-item>
                        <v-carousel-item class="center">
                          <div class="wi-80 wi-sm-100 ma-auto">
                            <div class=" ma-auto mt-5">
                              <p class="ma-auto left">{{wording.paymentMethod.question}}.</p>
                              <p class="ma-auto left mb-10"><em>{{wording.paymentMethod.explain}} :</em></p>
                            </div>

                            <j-price-tag :wording="wording" :success-rate="successRate" :currency="activePlan.currency ? activePlan.currency : '€'" :theme="selectedPlan == 3 ? selectedThemeName : ''" :installments="installments" :price="activePlan.price" :installment="installment" :name="activePlan.text.split('-')[0]" :paysuccess="paysuccess" ></j-price-tag>

  
                            <div class="ma-auto mt-5" v-if="'plans' in activePlan && 'success' in activePlan.plans">
                              <v-switch class="ma-auto" v-model="paysuccess" @change="changeCPF" :label="wording.paymentMethod.success"></v-switch>
                              <p><em class="smallTxt">{{wording.paymentMethod.successExplain}}.</em></p>
                            </div>
                            <div class="ma-auto mt-5" v-if="possibleInstallments.length">
                              <v-switch class="ma-auto pa-0" v-model="installments" @change="changeCPF" :label="wording.paymentMethod.installments"></v-switch>
                              <p><em class="smallTxt">{{wording.paymentMethod.installmentsExplain}}</em></p>
                            </div>
                            <v-expand-transition>
                              <div v-if="installments">
                                  <v-select
                                    :items="possibleInstallments"
                                    class="ma-auto mb-10 mt-5"
                                    :label="wording.paymentMethod.installmentsNumber"
                                    v-model="installment"
                                    hide-details="auto"
                                  ></v-select>
                              </div>
                            </v-expand-transition>

                            

                            <div v-if="activePlan.price >= 660" class=" ma-auto">
                              <v-switch class="ma-auto" v-model="CPF" @change="toggleCPF" :label="wording.paymentMethod.helpLabel"></v-switch>
                              <p class="mb-0"><em class="smallTxt">{{wording.paymentMethod.helpExplain}} <a v-if="lang === 'fr'" href="https://jobtimise.com/comment-financer-un-bilan-coaching-jobtimise/" target="_blank" >Notre article à ce sujet</a> </em></p>
                            </div>
                            <v-expand-transition >
                              <div v-if="CPF">
                                <v-select
                                  :label="wording.paymentMethod.helpQuestion"
                                  :items="helps[lang]"
                                  class="ma-auto mt-5"
                                  v-model="aide"
                                  hide-details="auto"
                                ></v-select>
                              </div>
                            </v-expand-transition>



                            <v-expand-transition>
                              <div v-if="!installments && !CPF || CPF && aide.length || installments && installment>0" class="wi-100 d-flex justify-center">
                                <v-btn class="btn btn-primary ma-auto mt-10 outlined" outlined v-on:click.prevent="carouselIndex++">{{wording.basics.next}}</v-btn>
                              </div>
                            </v-expand-transition>
                            <p class="back smallTxt center mb-0 mt-10" style="cursor:pointer;color:grey;" @click="carouselIndex--">
                              <v-btn class="mr-2" outlined x-small fab color="grey">
                                <v-icon>mdi-arrow-left</v-icon>
                              </v-btn>
                              {{wording.paymentMethod.back}}
                            </p>
                          </div>
                        </v-carousel-item>
                        <v-carousel-item>
                          <div class="wi-80 wi-sm-100 ma-auto center">
                            <p class="ma-auto left mb-10">{{wording.legal.intro}}</p>
                            <p class="legal left" v-html="legalNotice">
                                  
                            </p>
                            <v-checkbox v-model="CGV" :label="wording.legal.optin" hide-details="auto"></v-checkbox>
                            <v-expand-transition>
                              <div v-if="CGV" class="wi-100 d-flex justify-center">
                                <v-btn class="btn btn-primary mt-10 mx-auto outlined" outlined v-on:click.prevent="carouselIndex++">{{wording.basics.next}}</v-btn>
                              </div>
                            </v-expand-transition>
                            <p class="back smallTxt center mb-0 mt-10" style="cursor:pointer;color:grey;" @click="carouselIndex--">
                              <v-btn class="mr-2" outlined x-small fab color="grey">
                                <v-icon>mdi-arrow-left</v-icon>
                              </v-btn>
                              {{wording.legal.back}}
                            </p>
                          </div>
                        </v-carousel-item>
                        <v-carousel-item class="center">
                          <div class=" ma-auto mt-5 mb-5">
                            <p class="ma-auto left">{{wording.checkout.thank}} {{user.firstname}}, {{wording.checkout.intro}}</p>
                          </div>
                          <j-price-tag :wording="wording" :success-rate="successRate" :currency="activePlan.currency ? activePlan.currency : '€'" :theme="selectedPlan == 3 ? selectedThemeName : ''" :paysuccess="paysuccess" :installments="installments" :price="activePlan.price" :installment="installment" :name="activePlan.text.split('-')[0]"></j-price-tag>
                          <div class="wi-80 wi-sm-100 ma-auto">
                            <div v-if="CPF" class="wi-100 d-flex justify-center">
                              <v-btn class="btn btn-primary mt-10 mb-5 ma-auto" v-on:click.prevent="register">{{wording.checkout.start}}</v-btn>
                            </div>
                            <div class=" ma-auto mt-10 mb-2"  v-if="!CPF">
                              <h3 :class="['left '+country]"><v-icon>mdi-lock-check</v-icon>{{wording.checkout.title}}</h3>
                              <p class="ma-auto left"><em>{{wording.checkout.explain}}</em></p>
                              <p class="ma-auto left mt-5" v-if="installments"><em>{{wording.checkout.installment}} {{installment}} {{wording.checkout.installmentEnd}}</em></p>

                            </div>
                            <j-card-container v-if="!CPF" :language="lang" :wording="wording" v-bind="{route:'onboard',user:user,installments:installments,installment:installment,price:stripePlanId,paysuccess:paysuccess,successrate:successRate,country:country, journey: journey}" v-on:done="purchaseDone" v-on:loading="loading=true" v-on:error="handleStripeErr" class="stripe-el ma-auto mt-5"></j-card-container>
                            <p class="back smallTxt center mb-10 mt-10" style="cursor:pointer;color:grey;" @click="carouselIndex--">
                              <v-btn class="mr-2" outlined x-small fab color="grey">
                                <v-icon>mdi-arrow-left</v-icon>
                              </v-btn>
                              {{wording.checkout.back}}
                            </p>
                          </div>
                        </v-carousel-item>
                        <v-carousel-item>
                          <div class=" ma-auto mt-5 mb-5">
                            <p class="ma-auto mb-5 center wi-60 wi-sm-100 ">
                              <v-icon :color="country === 'ch' ? 'red' : 'primary'" class="hugeTxt mb-5">mdi-file-check</v-icon>
                              <br> {{wording.basics.thank}} {{user.firstname}}, {{wording.completion.thank}} 
                            </p>
                            <div class="ma-auto mb-5  wi-60 wi-sm-100 ">
                              <p >
                                {{wording.completion.emailConfirm}}
                              </p>
                              <p>{{wording.completion.cvExplain}}</p>
                              <p class="grey_ caption"> {{wording.completion.cvExplain2}}<a target="_blank" href='https://www.ilovepdf.com/fr/compresser_pdf'>{{wording.completion.cvExplain2End}}</a><em></em></p>
                              <v-file-input
                                ref="cvinput"
                                v-model="resume"
                                accept=".pdf"
                                :label="wording.completion.cvInput"
                                truncate-length="20"
                                show-size dense filled
                                @change="checkFileInput($event)"
                                >
                              </v-file-input>
                            </div>
                            <div class="wi-100 d-flex justify-center">
                              <v-expand-transition>
                                <v-btn v-if="resume" class="btn btn-primary ma-auto outlined" outlined v-on:click.prevent="uploadCV()">{{wording.completion.cvSend}}</v-btn>
                              </v-expand-transition>
                            </div>
                          </div>
                        </v-carousel-item>
                        <v-carousel-item>
                          <div class="ma-auto mt-5 mb-5">
                            <p class="ma-auto mb-5 center wi-60 wi-sm-100 ">
                              <v-icon :color="country === 'ch' ? 'red' : 'primary'" class="hugeTxt mb-5">mdi-check-circle</v-icon>
                              <br> {{wording.basics.thank}} {{user.firstname}}, {{wording.completion.endTitle}}
                            </p>
                            <p class="ma-auto text-center wi-60 wi-sm-100">{{wording.completion.outro}}</p>
                            <p class="ma-auto text-center wi-60 wi-sm-100">{{wording.completion.bye}}</p>
                          </div>
                        </v-carousel-item>
                      </v-carousel>

                  </v-form>
                  <p class="mt-5 tinyTxt absolute bottom center wi-100"><em>{{wording.footer.contact}} <a target="_blank" href="mailto:compta@jobtimise.com">compta@jobtimise.com</a></em></p>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
    </template>
    </j-page-template>
 </div>
</template>

<script>
import { apiURL, language, env, country } from '@/../config'
import axios from 'axios'
import { devPlans, devWorkshops, devElearning } from '../plans/plans-dev.js'
import { prodPlans, prodWorkshops, prodElearning } from '../plans/plans-prod.js'
import { frWording } from '../lang/fr.js'
import { enWording } from '../lang/en.js'
import { frLegalNotice } from '../lang/legal/fr.js'
import { enLegalNotice } from '../lang/legal/en.js'

export default {
  name: 'Home',
  data(){
    return {
        wording:language==='en'?enWording:frWording,
        lang: language,
        legalNotice:language==='en'?enLegalNotice:frLegalNotice,
        user:{
          firstname:'',
          lastname:'',
          email:'',
          address:'',
          phone:''
        },
        rules: {
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || this.wording.error.email
          }
        },
        resume:null,
        CGV:false,
        CPF: false,
        aide:'',
        selectedPlan: '',
        selectedTheme: '',
        selectedWorkshop: '',
        selectedElearning: '',
        carouselIndex:0,
        api:apiURL,
        loading: false,
        loading_message:'',
        plans:env==='prod'?prodPlans:devPlans,
        themes: [
          {text:`Préparer un entretien d'embauche`,slug:'interview'},
          {text:`Apprendre à réseauter (avec Linkedin) et me créer des opportunités`,slug:'network'},
          {text:`Refaire mes CVs, lettre de motivation, profil Linkedin`,slug:'tools'},
          {text:`Faire un bilan de carrière, de compétences, d'orientation`,slug:'assessment'}
        ],
        workshops:env==='prod'?prodWorkshops:devWorkshops,
        elearning:env==='prod'?prodElearning:devElearning,
        helps:{ fr : ['Compte Professionel de Formation','FNE (chômage partiel)','FIFPL et FAF (indépendants)','Autre'], en: ['Company grant','State financing','other']},
        installments: false,
        installment:0,
        paysuccess: false,
        alert_message:'',
        alert_btn:'OK',
        alert:false,
        nonce:'',
        changedOffer:false,
        country: country
    }
  },
  created(){
    document.body.style.opacity = 1
    this.loading_message = this.wording.completion.loading
  },
  computed:{
      validCredentials() {
        return !!this.user.firstname && !!this.user.lastname && !!this.user.phone && this.rules.email(this.user.email) === true && !!this.user.address
      },
      selectablePlans(){
        return this.plans.filter(e=>{
          return e.countries.includes(this.country)
        })
      },
      payable(){
        let phrase = ''
        this.possibleInstallments.forEach((el,index)=>{
          phrase+=`${el}`
          phrase = index == this.possibleInstallments.length - 1 ?  phrase : phrase+ " "+ this.wording.basics.or + " "
        })
        return phrase
      },
      selectedThemeName(){
        if(this.selectedTheme){
          const th = this.selectedTheme
          const t =  this.themes.filter(e=>{return e.slug == th })[0]
          return t.text || ''
        }else{
          return ''
        }
      },
      installmentSlug(){
        switch(this.installment){
          case 3:
            return 'three_months'
          case 2:
            return 'two_months'
          case 5:
            return 'five_months'
          case 10:
            return 'ten_months'
          default:
            return 'three_months'
        }
      },
      possibleInstallments(){
        return this.activePlan.possible_installments || []
      },
      stripePlanId(){
        const mode = this.paysuccess ? 'success' : 'full'
        if(this.selectedPlan && ![3,5].includes(this.selectedPlan) || this.selectedPlan == 3 && this.selectedWorkshop || this.selectedPlan == 5 && this.selectedElearning){
          return this.installments ? this.activePlan.plans[mode][this.installmentSlug] : this.activePlan.plans[mode]['default']
        }else {
          return ''
        }
      },
      activePlan(){
        let plan
        if(this.selectedPlan && ![3,5].includes(this.selectedPlan) ){
          plan = this.plans.filter(el=>{return el.id==this.selectedPlan})[0]
        }else if(this.selectedPlan === 3 && this.selectedTheme && this.selectedWorkshop){
          plan = this.workshops[this.selectedTheme].filter(el=>{return el.id==this.selectedWorkshop})[0]
        }else if(this.selectedPlan === 5 && this.selectedElearning){
          plan = this.elearning.filter(el=>{return el.id==this.selectedElearning})[0]
        }else{
          plan = {price: 0, text:'',id:''}
        }
        return plan
      },
      successRate () {
        if( typeof this.activePlan === 'object' && 'plans' in this.activePlan ) return this.activePlan.plans.successRate ?? 0
        else return 0
      },
      journey () {
        return this.activePlan.journey
      }
    },
    watch:{
      country(e){
        if(e==="ch"){
          this.$vuetify.theme.themes.light.primary = '#E74E50'
        }else{
          this.$vuetify.theme.themes.light.primary = '#3AC2A6'
        }
      }
    },
    methods:{
      async firstSlide(){
        this.carouselIndex++
        try{
          const gtoken = await this.$recaptcha('notice_prospect')
          await axios.post(this.api+'public/notice-prospect',{recaptcha:gtoken,user:this.user})
          this.$gtag.event('pre_sign_up',{
            'event_category': 'register'
          })
        }catch(e){
          console.log(e)
        }

      },
      handleStripeErr(err){
        this.loading = false
        this.alert_message = err
        this.alert = true
      },
      changeCPF(e){
        if(e){
          this.CPF = false
        }
      },
      toggleCPF(e){
        if(e){
          this.paysuccess = false
          this.installments = false
        }
      },
      purchaseDone(){
          this.loading = false
          this.carouselIndex++
          this.$gtag.event('sign_up_done',{
            'event_category': 'register'
          })
      },
      async register(){
        this.loading = true
        try{

          const gtoken = await this.$recaptcha('free_register')
            
          await axios.post(this.api+'public/free-register',{recaptcha:gtoken,user:this.user,price:this.stripePlanId,aide:this.aide,country:this.country,language:this.lang,journey: this.journey })
          this.purchaseDone()
  
  
        }catch(e){
          this.$emit('error', `${this.wording.error.following}: ${e.response.data.message}`)
  
        }
      },
      resetAll(){
        this.selectedPlan = ''
        this.resetOffer()
      },
      resetOffer(){
        this.selectedTheme = ''
        this.selectedWorkshop = ''
        this.installments= false
        this.installment = 0
        this.paysuccess=false
        this.resetCPF()

        if(this.changedOffer){
          this.$gtag.event('change_offer',{
            'event_category': 'register'
          })
        }else{
          this.$gtag.event('choose_offer',{
            'event_category': 'register'
          })
          this.changedOffer = true
        }
      },
      resetCPF(){
        this.CPF = false
        this.aide = ""
      },
      async checkFileInput(e){
        let size = e ?  (e.size/(1024*1024)).toFixed(2) : 0
        if(size > 10){
          this.alert = true
          this.alert_message = this.wording.error.cvSize
          this.$refs.cvinput.reset()
        }else{
          const cNonce = await this.$recaptcha('usage_nonce')
          const res = await axios.post(this.api+'public/usage-nonce',{ usage : 'uploadCV', recaptcha : cNonce })
          this.nonce = res.data.nonce
        }
      },
      async uploadCV(){
        this.loading_message=this.wording.completion.cvUpload
        this.loading=true
        try{
            const formData = new FormData()
            formData.append("user",JSON.stringify(this.user) )
            const gtoken = await this.$recaptcha('upload_cv')
            formData.append("cv",this.resume,`${this.user.lastname.toUpperCase()}_${this.user.firstname.toUpperCase()}.pdf` )

            await axios.post(this.api+'public/upload-cv',formData, { headers: { 'Content-Type': 'multipart/form-data', 'Nonce' : this.nonce, 'Recaptcha': gtoken } })
            this.carouselIndex++
          }catch(e){
            this.alert = true
            this.alert_message = this.wording.error.basic
            console.log(e)
          }finally{
            this.loading = false
          }
  
      }
    }
}
</script>
