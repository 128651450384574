<template>
    <v-list-item @click="$emit('hidedrawer')"  :to="link">
          <v-list-item-action>
            <v-icon>mdi-{{icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{text}}</v-list-item-title>
          </v-list-item-content>
    </v-list-item>
</template>

<script>


export default {
  name: 'drawer-item',
  props:['link','text','icon']
}
</script>
