import '@fortawesome/fontawesome-free/css/all.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Jcomponents from '@/assets/jobtimise-common'
import Pcomponents from '@/components'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import VueGtag from 'vue-gtag'
import { gtagID } from '@/../config'

(async () => {
    Vue.use(Jcomponents)
    Vue.use(Pcomponents)
    Vue.use(VueGtag, {
      config: { id: gtagID }
    })

    Vue.config.productionTip = false
    
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
})()
