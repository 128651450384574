import CardContainer from './CardContainer'
import PriceTag from './PriceTag'

export default {
    install(Vue){
        Vue.mixin({
            created() {
                const jcomp = {CardContainer,PriceTag}
                for(let key in jcomp){
                    Vue.component('j'+key,jcomp[key])
                }
            }
        })
    }
}