<template>
  <div id="userForm">
    <div class="justify-center" >
        <v-form :class='{"form-inline":inline}' @submit.prevent="onSubmit">
            <v-text-field  required :disabled="disabled.includes('firstname')" @focus="noError" @keydown="noError" v-if="usage.includes('firstname')" id="inline-form-input-name" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" outlined placeholder="Prénom" v-model ='user.firstname' ></v-text-field>
            <v-text-field  required :disabled="disabled.includes('lastname')" @focus="noError" @keydown="noError" v-if="usage.includes('lastname')" id="inline-form-input-lastname" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" outlined placeholder="Nom" v-model ='user.lastname'></v-text-field>
            <v-text-field  required :disabled="disabled.includes('email')" @focus="noError" @keydown="noError" v-if="usage.includes('email')"  id="inline-form-input-email" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" outlined  type="email" placeholder="Email" v-model='user.email'></v-text-field>
            <v-text-field  required :disabled="disabled.includes('phone')" @focus="noError" @keydown="noError" v-if="usage.includes('phone')"  id="inline-form-input-phone" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" outlined type="phone" placeholder="Téléphone" v-model='user.phone'></v-text-field>
            <v-text-field  required :disabled="disabled.includes('password')" @focus="noError" @keydown="noError" v-if="usage.includes('password')" id="inline-form-input-password" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" outlined type="password" placeholder="Mot de passe" v-model='user.password'></v-text-field>
            <v-text-field  required :disabled="disabled.includes('confirmPassword')" @focus="noError" @keydown="noError" v-if="usage.includes('confirmPassword')" id="inline-form-input-password-confirm" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" outlined type="password" placeholder="Confirmation mot de passe" v-model='user.confirmPassword'></v-text-field>
            <div  v-if="usage.includes('trial')">
              <v-switch @focus="noError" id="inline-form-input-trial" label="Utilisateur en essai" v-model="user.trial.isOnTrial"></v-switch>
              <span v-if="user.trial.isOnTrial">
                <p>Date de fin d'essai</p>
                <j-date-picker class="wi-20" :date="user.trial.end" @change="user.trial.end = $event" ></j-date-picker>
              </span>
            </div>
            <div v-if="usage.includes('optin')" class="wi-100 mt-4 d-flex caption align-center" >
              <v-checkbox @click="noError" class="caption ma-0 mb-0 pa-0" v-if="usage.includes('optin')" v-model="user.optin" ></v-checkbox>
              <p class="ma-0">J'accepte les <a class="ml-1" target="_blank" href="https://jobtimise.com/conditions-dinscription/"> conditions d'inscription de Jobtimise</a>.</p>
            </div>

            <j-course-choice v-if="usage.includes('courses')" v-bind:checked.sync="user.course_registration"></j-course-choice>
            
            <div class="wi-100 text-center">
              <v-btn type="sumbit" :color="button.color" :class="['mt-5  primary j-btn',`v-size--${button.size}`]" >{{submitWord}}</v-btn>
            </div>

        </v-form>
    </div>
    <div class="justify-center mt-5">
      <v-expand-transition>
        <v-alert v-if='errorsPresent'  class="red lighten-2 white_ text-center">{{errorMessage}}</v-alert>
      </v-expand-transition>
    </div>
</div>
</template>

<style lang="scss" >
  #userForm{
    input[type=text],input[type=email],input[type=password],input[type=phone]{
      padding: 0;
    }
    .v-input--checkbox{
      .v-messages{
        display: none;
      }
      .v-input__slot{
        margin-bottom: 0;
      }
    } 

    .v-text-field__details{
      display: none;
    }
    form{
      width: 100%;
    }
  }
</style>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// For more options see below
Vue.use(VueReCaptcha, { siteKey: '6LdtsdEZAAAAACrA8z9exAO-ICIBkTZ7ic3FLSq_' })

export default {
  name: 'user-form',
  components:{
  },
  props: {
    control:{
      type: Boolean,
      required: false,
      default: ()=> {return true}
    },
    usage: {
      type: Array,
      required : false,
      default: ()=> {return ['firstname','lastname','email']}
    },
    disabled: {
      type: Array,
      required : false,
      default: ()=> {return []}
    },
    submitWord:{
      type: String,
      required: false,
      default: () => {return "Submit"}
    },
    inline: {
      type: Boolean,
      rquired: false,
      default: () => {return false}
    },
    button: {
      type: Object,
      rquired: false,
      default: () => {
        return {
          color: 'primary',
          size: 'x-large'
        }
      }
    },
    user: {
      type: Object,
      required: false,
      default: () => {
        return {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          password:'',
          confirmPassword:'',
          course_registration:[],
          optin:false,
          trial:{
            isOnTrial: false,
            end: new Date()
          }
        }
      }
    }
  },
  data() {
    return {
      errorsPresent : false,
      errorMessage: "Une erreur est survenue, veuillez réessayer."
    }
  },
  methods: {
    checkPassword: function(){
      // let reg =  /^(?=.*[0-9])(?=.*[!@#$%^&*_-])[a-zA-Z0-9!@#$%^&*_-]{8,40}$/
      // return reg.test(this.user.password)
      return this.user.password.length > 7
    },
    checkEmail: function(){
      let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(String(this.user.email).toLowerCase())
    },
    noError: function(){
            this.errorsPresent = false
            this.$emit('focus')
    },
    testFields: function(){
      return new Promise((resolve,reject)=>{
        if(!this.control)
          resolve()
        
        let u = this.user
        let optin = Boolean(this.user.optin)

        this.usage.map(function(el){
          if(u[el]===''){
            reject("Merci de remplir tous les champs requis.")
          }
        })

        if(this.usage.includes('email') && !this.checkEmail()){
          reject("Merci de renseigner un email vailde")
        }else if(this.usage.includes('password') && !this.checkPassword()){
          // reject('Merci de renseigner un mot de passe plus fort: au moins 8 caratères, une majuscule, une minuscule, un chiffre et un caractère spécial.')
          reject('Merci de renseigner un mot de passe comportant au moins 8 caratères.')
        }else if(this.usage.includes('confirmPassword') && this.user.password != this.user.confirmPassword){
          reject("La confirmation de mot de passe n'est pas identique au mot de passe.")
        }else if(this.usage.includes('optin') && !optin){
          reject("Merci d'accepter nos CGU.")
        }

        resolve('fields OK')
      })
      
    },
    onSubmit: async function() {
        try{
          await this.testFields()
          const recaptchaRes = await this.$recaptcha('login')
          this.$emit('validate', {user:this.user,recaptcha:recaptchaRes})
        }catch(e){
          this.errorMessage =e
          this.errorsPresent = true
        }
    }
  }
}
</script>