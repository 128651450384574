<template>
  <div class="quality-input" @mouseover="listenToEnter()">
    <em class="text-caption wi-100 text-left d-block" v-if="!(typeof limit === 'undefined') && qualities.length > limit && state !== 'done'">
        <span class="d-block mb-1" v-if="list.length === 0" >Choisissez jusqu'à <strong>{{limit}}</strong> {{limit > 1 ? content.itemName.plural : content.itemName.single }} <span v-if="offset">( minimum {{offset}} )</span> </span>
        <span class="d-block mb-1" v-else-if="list.length < limit" ><strong class="primary--text">{{list.length}}</strong>/{{limit}} {{list.length > 1 ? content.itemName.plural : content.itemName.single }} sélectionné{{content.itemName.gender === 'fem' ? 'e':''}}{{list.length > 1 ? 's':'' }} <span v-if="offset && list.length < offset">( minimum {{offset}} )</span></span>
        <span v-else class="d-block primary--text"><v-icon color="primary">mdi-check-circle</v-icon> Vous avez choisi {{limit}} {{content.itemName.plural}}</span>
    </em>
    <div :class="state">
    <v-chip v-for="(quality, index) in qualities" :color="list.includes(quality) ? 'primary' : ''" @click="toggleCat(quality)" :key="index">{{quality.title[0].toUpperCase() + quality.title.slice(1)}}</v-chip>
    </div>
    <em v-if="!(typeof limit === 'undefined') && qualities.length > limit && state !== 'done' && qualities.length > 20" class="text-caption wi-100 text-left d-block">
        <span class="d-block mb-1" v-if="list.length === 0" >Choisissez jusqu'à <strong>{{limit}}</strong> {{limit > 1 ? content.itemName.plural : content.itemName.single }} <span v-if="offset">( minimum {{offset}} )</span> </span>
        <span class="d-block mb-1" v-else-if="list.length < limit" ><strong class="primary--text">{{list.length}}</strong>/{{limit}} {{list.length > 1 ? content.itemName.plural : content.itemName.single }} sélectionné{{content.itemName.gender === 'fem' ? 'e':''}}{{list.length > 1 ? 's':'' }} <span v-if="offset && list.length < offset">( minimum {{offset}} )</span></span>
        <span v-else class="d-block primary--text"><v-icon color="primary">mdi-check-circle</v-icon> Vous avez choisi {{limit}} {{content.itemName.plural}}</span>
    </em>
    <v-expand-transition>
        <span v-if="offset === 0 || list.length && ( list.length >= offset || typeof offset === 'undefined' ) && state !== 'done'">
            <v-btn dense rounded outlined color="primary" @click="validate()">Valider</v-btn>
        </span>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
    name: 'quality-input',
    props: ['content', 'state', 'qualities', 'limit', 'offset'],
    data(){
        return {
            eventActive : false,
            list: [],
        }
    },
    methods: {
        validate () {
            if (this.state !== 'done' ){
                const payload = {}
                const toAdd = []
                if( this.content.itemValue && this.list.some( e => { return typeof e === 'object' }) ) {
                    for (let l of this.list ){
                        if ( typeof l === 'object' && l[this.content.itemValue] ) toAdd.push(l[this.content.itemValue])
                        else if ( typeof l === 'string' ) toAdd.push(l)
                    }
                    payload[this.content.inputName] = toAdd
                } else {
                    payload[this.content.inputName] = this.list
                }
                this.$emit('done', payload)
            }
        },
        toggleCat (e) {
            if( this.list.includes(e) ) this.list = this.list.filter( l => { return l !== e })
            else if(typeof this.limit === 'undefined' || this.list.length < this.limit) this.list.push(e)
        },
        handleKeydown (e) {
            if(e.code === "Enter") {
                this.validate()
            }
        },
        listenToEnter () {
            if (!this.eventActive) {
                this.eventActive = true
                document.addEventListener('keydown', e => {
                    if (e.code === "Enter") this.validate()
                })
            }
        }
    }
}
</script>

<style lang="scss">
    .quality-input{
        width: 100%;
        >div{
            padding: 0.3em;
            &.done{
                opacity: 0.3;
                pointer-events: none;
            }
            .v-chip{
                margin: 0.3em;
                cursor: pointer;
                white-space: normal;
                padding: 0.5rem 1rem!important;
                overflow: hidden;
                border-radius: 5rem;
                height: auto;
            }
        }
        >span{
            display: block;
        }
        .v-btn{
            margin: 1em 0 0.5em;
            width: 100%;
        }
    }
</style>