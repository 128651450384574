import {apiURL} from '@/../config';
import { USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_LOGOUT, USER_ADD_SESSION, USER_VIEW_SESSION, USER_UPDATE_PROFILE } from "../actions/user";
import Vue from "vue";
import { AUTH_LOGOUT } from "../actions/auth";
import axios from "axios";
import store from '@/store'

const state = { status: "", profile: {}, notifications: [] }

const getters = {
  getProfile: state => state.profile,
  getFullName: state => state.profile.firstname + " " + state.profile.lastname,
  getFirstname: state => state.profile.firstname,
  getLastname: state => state.profile.lastname,
  getCustomerId: state => state.profile.subscription.customerId,
  isSubscribed: state => state.profile.subscription.subscribed,
  getEmail: state => state.profile.email,
  getUserSessionsList: state=>state.profile.list_of_sessions,
  getUserAssessments: state=>state.profile.assessments,
  isProfileLoaded: state => !!state.profile.email,
  isAdmin: state => state.profile.admin,
  hasPassword : state => { return !!state.profile.activationToken },
  getActivationToken : state => { return state.profile && state.profile.activationToken ? state.profile.activationToken : '' },
  getNotifications : state => {
    const notifs = []
    if(state.profile && !!state.profile.activationToken ){
      notifs.push({
        text: `Définissez votre mot de passe`,
        icon: 'mdi-lock-alert-outline',
        type: 'error',
        cb: () => {
          store.dispatch('open_password_popup')
        }
      })
    }

    return [...notifs, ...state.notifications]
  }
}

const actions = {
  [USER_REQUEST]: async ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    try {
      const userRequest = await axios.post(apiURL + 'auth/me')
      commit(USER_SUCCESS, userRequest.data.profile)
    }catch(err) {
      commit(USER_ERROR);
      commit(USER_LOGOUT);
      dispatch(AUTH_LOGOUT);
    }
  },
  [USER_ADD_SESSION]: async ({ commit },payload) => {
    try{
      const updatedProfile = await axios.post(apiURL + 'auth/me/log-session/finished',payload);
      commit(USER_SUCCESS, updatedProfile.data.profile);
    }catch(err){
      commit(USER_ERROR);
    }
  },
  [USER_VIEW_SESSION]: async ({ commit },payload) => {
    try{
      const updatedProfile = await axios.post(apiURL + 'auth/me/log-session/viewed',payload);
      commit(USER_SUCCESS, updatedProfile.data.profile);
    }catch(err){
      commit(USER_ERROR);
    }
  },
  [USER_UPDATE_PROFILE]: async ({ commit },payload) => {
    try{
      commit(USER_SUCCESS, payload.user);
    }catch(err){
      commit(USER_ERROR);
    }
  },
  updateMe: async({commit,getters},payload)=>{
    await commit(USER_REQUEST);
    try{
      const user = {
        firstname: payload.user.firstname,
        lastname: payload.user.lastname,
        _id :getters.getProfile._id,
        email:getters.getEmail
      }
      const updatedProfile = await axios.post(apiURL + 'auth/me/update',{ user, recaptcha:payload.recaptcha })
      commit(USER_SUCCESS, updatedProfile.data.queryRes ?? updatedProfile.data);
      return true
    }catch(err){
      commit(USER_ERROR);
      throw err;
    }
  },
  set_password : async ( { commit, getters }, payload ) => {
    await axios.post(`${apiURL}public/activate-account/${getters.getActivationToken}`,{password: payload.user.password, recaptcha : payload.recaptcha })
    commit('activation_done')
    return 
  },
  remove_activation_token : async ( { commit } ) => {
    commit('activation_done')
    return
  }
}

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
    return;
  },
  [USER_SUCCESS]: (state, profile) => {
    state.status = "success";
    Vue.set(state, "profile", profile);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [USER_LOGOUT]: state => {
    state.profile = {};
  },
  activation_done: state => {
    state.profile.activationToken = ''
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
