<template>
  <v-navigation-drawer
    class="navigation-drawer"
    :value="value"
    @input="$emit('input', $event)"
    temporary
    app
    :width="['xs','ms'].includes($vuetify.breakpoint.name) ? '80%' : '25%'"
    :right="side === 'right'"
  >
    <i class="closeIcon" @click="$emit('input', false)"></i>
    <v-list>
      <j-drawer-item icon="home" text="Accueil" link="/"></j-drawer-item>
      <span v-for="(item, index) in contentNavigation" :key="index">
        <div v-if="item.type === 'separator' && item.isActive" class="d-flex align-center">
          <v-divider></v-divider>
            <p class="text-center ma-0 pa-3 font-weight-light text-secondary">{{item.text}}</p>
          <v-divider></v-divider>
        </div>
        <div v-else-if="item.type === 'logout' && item.isActive" class="d-flex align-center">
          <j-logout :absolute="false"></j-logout>
        </div>
        <span v-else>
          <j-drawer-item
            v-if="item.isActive"
            :icon="item.icon"
            :text="item.text"
            :link="item.link"
          ></j-drawer-item>
        </span>
      </span>
      <span v-if="!this.$store.getters.isAuthenticated">
        <j-drawer-item
          v-if="appRegister"
          icon="check"
          text="Inscription"
          link="/register-infos"
        ></j-drawer-item>
        <j-drawer-item
          icon="login"
          text="Connexion"
          link="/login"
        ></j-drawer-item>
      </span>
    </v-list>
    <div
      v-if="this.$store.getters.isAuthenticated && logout"
      class="wi-100 d-flex justify-center"
    >
      <j-logout></j-logout>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { appRegister } from '@/../config'

export default {
  name: 'drawer',
  props: {
    value: {},
    contentNavigation: {
      type: Array,
      required: false
    },
    side: {
      type: String,
      required: false
    },
    logout:{
      type: Boolean,
      required: false,
      default: ()=> {return true}
    }
  },
  data() {
    return {
      appRegister: appRegister
    }
  }
}
</script>

<style lang="scss">
.logout {
  position: absolute;
  bottom: 2em;
}
.navigation-drawer {
  padding-top: 3.5rem;
  @include closeIcon(rgb(120, 120, 120), 2rem, 0.1rem, 1rem, 1rem);
}
</style>