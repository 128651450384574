<template>
      <div>
        <j-card-infos @validateCard="validateCard" :cta="'Commencer mon coaching'" :stripetitle="wording.stripe.title"></j-card-infos>
      </div>
</template>

<script>
import { apiURL, stripePublic } from '@/../config'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'

export default {
    name: 'card-container',
    data(){
      return {
        spk:stripePublic,
        stripe:undefined,
        api: apiURL,
        stripe_token:undefined
      }
    },
    props: ['wording', 'user','price','installments','installment','paysuccess','successrate','route','recover','country','language','journey'],
    methods:{
      async validateCard(stripe_token) {
        try{
          this.$emit('loading');
          this.stripe_token = stripe_token
          this.purchase()
        }catch(e){
          const mess = e.response && e.response.data && e.response.data.message ?  e.response.data.message : "Votre paiement a été refusé par votre banque"
          this.$emit('error', `${this.wording.error.following}: ${mess}`);
        }
      },
      async purchase(){
        this.$gtag.event('start_payment',{
            'event_category': 'register'
        })
        
        try{
          const gtoken = await this.$recaptcha('confirmation')
          this.stripe= await loadStripe(this.spk);
          
          const req = await axios.post(this.api+'public/'+this.route,{recaptcha:gtoken,stripe_token:this.stripe_token,user:this.user,price:this.price,installments:this.installments,installment:this.installment,paysuccess:this.paysuccess,successrate:this.successrate,aide: this.CPF ? this.aide : '',country:this.country,language:this.language,journey: this.journey });
          if(req.data.action != "done"){
            const secure = await this.stripe.confirmCardPayment(req.data.message.client_secret);
            if(!secure.paymentIntent || secure.paymentIntent.status != 'succeeded'){
              throw secure;
            }else if(this.recover){
              await axios.post(this.api+'public/installment-confirmation',{user:this.user,price:this.price,intent:req.data.message.id,installments:this.installments,installment:this.installment,country:this.country,language:this.language});
            }else{
              await axios.post(this.api+'public/confirmation',{user:this.user,price:this.price,intent:req.data.message.id,installments:this.installments,installment:this.installment,paysuccess:this.paysuccess,successrate:this.successrate,country:this.country,language:this.language,aide: this.CPF ? this.aide : '', journey: this.journey});
            }
          }
  
          this.$emit('done');
        }catch(e){
          const mess = e.response && e.response.data && e.response.data.message ?  e.response.data.message : "Votre paiement a été refusé par votre banque"
          this.$emit('error', `${this.wording.error.following}: ${mess}`);
        }finally{
          this.$store.dispatch('stop_loading')
        }
      }
    }
}
</script>