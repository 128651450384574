import {apiURL} from '@/../config';
import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
  } from "../actions/auth";
  import { USER_SUCCESS, USER_LOGOUT} from "../actions/user";
  import axios from 'axios';
  
  const state = {
    token: localStorage.getItem("user-token") || "",
    status: "",
    hasLoadedOnce: false
  }
  
  const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
  };
  
  const actions = {
    [AUTH_REQUEST]: ({ commit , dispatch }, user) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST)
        axios.post(`${apiURL}public/auth`,user)
          .then(res => {
            localStorage.setItem("user-token", res.data.token )
            // set axios header to the token value.
            axios.defaults.headers.common['Authorization'] = res.data.token 
            setTimeout(()=>{
              commit(AUTH_SUCCESS, res.data.token)
              commit(USER_SUCCESS,res.data.profile)

              dispatch('open_popup',{message:`Bienvenue ${res.data.profile.firstname ?? ''}, vous êtes maintenant conneté(e).`})
              
              resolve(res.data)
            },1000)
          })
          .catch(err => {
              commit(AUTH_ERROR,err);
              localStorage.removeItem("user-token");
              reject(err.response || err);
          })
      });
    },
    [AUTH_LOGOUT]: async ({ commit }) => {
      try{ 
        commit(USER_LOGOUT)
        await axios.post(`${apiURL}auth/logout`)
       } catch (e) {
        console.log(e)
       } finally {
         commit(AUTH_LOGOUT)
         axios.defaults.headers.common['Authorization'] = ""
         localStorage.removeItem("user-token")
       }
    },
    'update_token': ({ commit  },token) => {
      axios.defaults.headers.common['Authorization'] = token
      localStorage.setItem("user-token", token)
      commit(AUTH_SUCCESS,token)
      return true
    }
  };
  
  const mutations = {
    [AUTH_REQUEST]: state => {
      state.status = "loading"
    },
    [AUTH_SUCCESS]: (state, token) => {
      state.status = "success"
      state.token = token;
      state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: state => {
      state.status = "error"
      state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: state => {
      state.token = ""
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  }
  