export const frWording = {
    basics: {
        next: 'Suivant',
        thank: 'Merci',
        or: 'ou',
        month: 'mois',
        loading: `Chargement en cours...`
    },
    credentials: {
        welcome: 'Bonjour et bienvenue chez Jobtimise !',
        intro : 'Faisons connaissance, indiquez-nous ces quelques informations sur vous :',
        yourFirstname: `Votre prénom`,
        yourLastname: `Votre nom de famille`,
        yourEmail: `Votre adresse email`,
        yourPhone: `Votre numéro de téléphone`,
        yourAddress: `Votre adresse postale complète`,
        usage: `Ces informations seront utilisées exclusivement pour vous contacter, si besoin, dans le cadre de votre inscription aux services Jobtimise.`,
        thank: `nous sommes ravis de vous connaître`
    },
    coaching: {
        question: `Dans quel cadre allons-nous vous accompagner ?`,
        yourChoice: `Choix de votre formule`,
        payableIn: `Payable en`,
        conditional: `ou`,
        installments: `fois`,
        back:`Retour aux informations`
    },
    paymentMethod: {
        question: `Choisissez votre mode de paiement`,
        explain: `Nous proposons différents financement, choisissez celui qui vous convient le mieux ci-dessous`,
        success: `Formule au résultat`,
        successExplain: `La formule au résultat vous permet de payer une partie du prix au succès de votre coaching, à votre prise de poste`,
        installments: `Payer en plusieurs fois`,
        installmentsExplain: `Vous pouvez choisir de payer en plusieurs fois, sans frais. Si vous faites ce choix, une partie du montant vous sera débité chaque mois.`,
        installmentsNumber: `Nombre d'échéances`,
        helpLabel: `Je finance ma formation via une aide publique`,
        helpExplain: `Différentes aides vous permettent de financer tout ou en partie des formations Jobtimise.`,
        helpQuestion: `De quelle aide s'agit-il ?`,
        back:`Retour aux choix de la formule`
    },
    priceTag: {
        installment: `fois sans frais`,
        duration: `par mois pendant`,
        instantCharge: `paiement immédiat`,
        successPart: `Partie au résultat`,
        successPartExplain: `Vous sera demandé en fin de coaching`,
        back: 'Retour aux offres'
    },
    legal: {
        intro: `Merci de lire attentivement et d'accepter les conditions générales de ventes ci-dessous :`,
        optin: `J'ai pris connaissance et accepte les conditions générales de vente ci-dessus`,
        back: `Retour aux financements`
    },
    checkout: {
        thank: `Merci pour ces informations`,
        intro: `nous sommes prêts à vous accompagner sur l'offre que vous avec choisie :`,
        start: `Commencer mon coaching`,
        title: `Vos informations de paiement`,
        explain: `Merci de renseigner vos informations de paiements ci dessous:`,
        installment: `Vous avez choisi un paiement en`,
        installmentEnd: `fois sans frais, le premier prélèvement aura lieu aujourd'hui, et les suivants à interval de 30 jours.`,
        back: `Retour aux CGV`,
        reinsurance: `Ces coordonnées serviront uniquement à vous prélever automatiquement jusqu'à la fin de vos échéances.`
    },
    stripe: {
        title: `Paiement sécurisé via`,
        start: `Commencer mon coaching`
    },
    completion: {
        loading: `Votre inscription est en cours...`,
        thank: `votre inscription est complète.`,
        emailConfirm: `Vous allez recevoir un mail de confirmation vous invitant à commencer votre coaching Jobtimise. `,
        cvExplain: `Pour nous permettre de commencer votre coaching rapidement, pouvez-vous nous joindre votre CV ci-dessous :`,
        cvExplain2: `Si votre CV dépasse 10MB vous pouvez le compresser sur `,
        cvExplain2End: `ce site`,
        cvInput: `Votre CV (PDF de 10MB ou moins)`,
        cvUpload: `Votre CV est en cours de transfert`,
        cvSend: `Envoyer mon CV`,
        endTitle: `votre inscription est terminée ! `,
        outro: `Vous allez être contacté par votre coachs Jobtimise d'ici 48h avec votre programme de coaching.`,
        bye: `À très vite !`
    },
    footer: {
        contact : `Pour tout problème ou toute demande de financement particulière, contactez`
    },
    error: {
        email: `Email invalide.`,
        basic: `Une erreur est survenue.`,
        following: `L'erreur suivante est survenue`,
        cvSize: `Merci d'uploader un CV au format PDF de moins de 10MB. \nVous pouvez en réduire la taille sur ce site : https://www.ilovepdf.com/`
    },
    oneShot: {
        welcome: `Bienvenue sur le module de paiement Jobtimise`,
        intro: `Avant tout merci de renseigner ces informations `,
        orderIntro: `Ci-dessous votre commande`,
        paymentOk: `votre paiement a bien été pris en compte.`
    }
}