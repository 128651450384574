export const enWording = {
    basics: {
        next: 'Next',
        thank: 'Thanks',
        or: 'or',
        month: 'month',
        loading: `Loading...`
    },
    credentials: {
        welcome: 'Hello and welcome to Jobtimise !',
        intro: `Let's get to know each other, give us some information about yourself:`,
        yourFirstname: `Your firstname`,
        yourLastname: `Your lastname`,
        yourEmail: `Your email address`,
        yourPhone: `Your phone number`,
        yourAddress: `Your address`,
        usage: `This information will be used exclusively to contact you, if necessary, in connection with your registration for Jobtimise services.`,
        thank: `we are delighted to know you`
    },
    coaching: {
        question: `How will we support you?`,
        payable: `<em>All our coaching are <strong>payable in installments.</strong></em>`,
        yourChoice: `Coaching of your choice`,
        payableIn: `Payable in`,
        conditional: `or`,
        installments: `installments`,
        back: `Back to my information`
    },
    paymentMethod: {
        question: `Choose your payment method`,
        explain: `We provide several financing options, choose the one that suits you best`,
        success: `Pay on success`,
        successExplain: `Pay a share of the price at the end of your coaching, when you take up your position`,
        installments: `Pay in installements`,
        installmentsExplain: `You can choose to pay in installments, without any additional fee. If you make this choice, a part of the amount will be debited each month.`,
        installmentsNumber: `Number of installments`,
        back: 'Back to coaching choice',
        helpLabel: `I pay through a financing aid`,
        helpExplain: `There are various ways to finance all or part of the Jobtimise training.`,
        helpQuestion: `What kind of financing is it?`
    },
    priceTag: {
        installment: `installments without fee`,
        duration: `each month during`,
        instantCharge: `instant payment`,
        successPart: `pay at the end`,
        successPartExplain: `to be paid at the end of your coaching`
    },
    legal: {
        intro: `Please read carefully and accept the general conditions of sale below:`,
        optin: `I have read and accept the general conditions of sale above`,
        back: `Back to financing options`
    },
    checkout: {
        thank: `Thank you for this information`,
        intro: `we are ready to accompany you on the offer that you have chosen:`,
        start: `Start my coaching`,
        title: `Your payment details`,
        explain: `Please enter your payment details below:`,
        installment: `You have chosen a payment in`,
        installmentEnd: `installments without extra fee, the first payment will be made today, and subsequent payments will be made at 30-day intervals.`,
        back: `Back to terms of use`,
        reinsurance: `This information will only be used to automatically debit your account until the end of your payment period.`
    },
    stripe: {
        title: `Secure payment by `,
        start: `Start my coaching`
    },
    completion: {
        loading: `Your registration is in progress...`,
        thank: `your registration is complete.`,
        emailConfirm: `You will receive a confirmation email inviting you to start your Jobtimise coaching. `,
        cvExplain: `To enable us to start your coaching quickly, please attach your resume below:`,
        cvExplain2: `If your resume exceeds 10MB you can compress it on `,
        cvExplain2End: `this website`,
        cvInput: `Your resume (PDF of 10MB or less)`,
        cvUpload: `Your Resume is loading`,
        cvSend: `Send my resume`,
        endTitle: `your registration is complete!`,
        outro: `You will be contacted by your Jobtimise coach within 48 hours with your coaching program.`,
        bye: `See you soon!`
    },
    footer: {
        contact: `For any problem or specific funding request, contact`
    },
    error: {
        email: `Invalid email.`,
        basic: `An error just occured.`,
        following: `The following error happened`,
        cvSize: `Please upload a resume in PDF format of less than 10MB \nYou can reduce its size on this site: https://www.ilovepdf.com/`
    },
    oneShot: {
        welcome: `Welcome to the Jobtimise payment module`,
        intro: `First of all, please fill in this information`,
        orderIntro: `Below is your order`,
        paymentOk: `your payment has been taken into account.`

    }
}
