import Vue from 'vue'
import VueRouter from 'vue-router'
import OnBoard from '../views/OnBoard.vue'

Vue.use(VueRouter)

function meta(title){
  const hyphen = title ? ' - ':''
  return{
    title: `${title}${hyphen}Jobtimise MachinaJob`,
  }
}

const routes = [
  {
    path: '/',
    name: 'OnBoard',
    component: OnBoard,
    meta: meta('OnBoard')
  },
  {
    path: '/pay/:stripeId',
    name: 'pay',
    component: () => import('../views/Pay.vue'),
    meta: meta('Pay')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
