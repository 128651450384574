export const enLegalNotice = `
GENERAL CONDITIONS OF REGISTRATION <br><br><br>
Preamble <br><br>
Unique Corn SARL (hereinafter: "the Company") is a company active in the field of vocational and educational guidance, vocational training, professional coaching and human resources consulting. <br>
These General Conditions of Service govern the operation of cases as different as those of executives, specialized workers, senior managers, trainees, high-level engineers or beginners.<br>
In general, the relationship between the Company and the recipients of its services is governed by the principles of good faith. Unique Corn SARL strives to be flexible and understanding. <br>
However, in the absence of express written agreement to the contrary by The Company, these terms and conditions alone shall govern the service relationship.
<br><br><br>
1. Definition<br><br>
"Jobtimise" is a trademark of Unique Corn Sàrl located at Rue du Petit Chêne 22, C/O Gaapex SARL, 1003 Lausanne - Switzerland VAT : CHE-494.233.370.<br>
"Contract" includes the present General Terms of Services, the description and pricing of the chosen formula as well as any written amendments to the General Terms of Services.<br>
"Subscriber" means the recipient of the career coaching benefits. <br>
"Coaching Period" means the actual period of accompaniment and coaching defined according to the accompaniment formula chosen by the Subscriber.<br>
"Transformation period" means the period of accompaniment subsequent to the period of accompaniment defined in the chosen accompaniment formula.<br>
"Lump Sum" means the lump sum amount due by the Subscriber at the beginning of the support that gives access to the Company's services.<br>
"Success Payment": this is the amount to be paid at the end of the Transition Period if the coaching is successful.<br>
<br><br><br>
2. Object<br><br>
1. The present General Conditions of Services aim at defining the contractual relations between the Company and the Subscriber. These conditions are applicable to all services offered by the Company through its website and through an Internet promotion. <br>
2. Unless otherwise expressly agreed in writing, subscription to any offer proposed by the Company implies unreserved acceptance by the Subscriber of these General Terms of Services.

<br><br><br>
3. Terms and conditions<br><br>
1. The Subscriber must register by completing the online registration form intended for this purpose. No pre-registration can be made by telephone or fax. The form may be returned by email in digital format (signed .pdf format or scan of the original copy signed manually). Within eight days of this pre-registration, the Subscriber will receive by email the present General Terms of Services, the description of the service requested, as well as its pricing. <br>
2. The enrollment will only be considered final upon receipt of payment of the Fixed Fee by the Subscriber himself or herself, or by third parties making the payment, specifying that the payment is for the Subscriber's enrollment fee.<br>
3. In the event of deferred payment of the Fixed Fee, registration is deemed final upon receipt of the first payment. <br>
4. The Subscriber agrees at the time of registration to make himself available to carry out all the coaching sessions assigned to him within a period of 6 months from the date of registration.
<br><br><br>
4. Remuneration of the Company <br><br>
1. Unless otherwise specified, the Company's services are paid for through : <br>
a. a Fixed Amount usually paid at the time of Subscriber's enrollment and ; <br>
b. a Success Payment which must be paid following the achievement of an event constituting the success of the accompaniment. <br>

2. In the case of a formula subject to a Success Fee, the Subscriber agrees to pay the said Fee as soon as the event characterizing said success occurs, as described below in Article 6. If this event occurs before all the coaching sessions have been used up, Jobtimise undertakes to offer alternative sessions in the same number or to provide the Subscriber with a credit note for the number of remaining sessions valid for 24 months. The Success Award will be due, however, without exception to Article 6.
<br><br><br>
5. Method of payment of the Fixed Amount<br><br>
1. Except as provided in Sections 12 and 13, the Lump Sum is due in full from the Subscriber regardless of termination of the Agreement by the Subscriber and notwithstanding any deferral of payment of the Lump Sum.
<br><br><br>
6. Eligibility and payment of the Success Payment<br><br>
1. The Success Award is due within 30 days of the successful completion of the coaching. The Subscriber has the duty to inform the Company of the success of the coaching as soon as it is completed.<br>
2. Is considered successful support if, at the end of the Transition Period, the Subscriber has alternatively:<br>
- a job offer that he decides to accept; <br>
- a promotion in his company or a significant salary increase of more than 10%;<br>
- decided to create his company alone or with partners;<br>
- joins a company as a partner.<br>
3. At the end of the Conversion Period, the Subscriber shall send to the Company by registered letter with acknowledgement of receipt, all documents proving that his professional situation has not changed and that no event constituting successful support has taken place. In particular, a Subscriber who remains unemployed will send a certificate of registration with a public job-seeker's service dated the current month. In the absence of this certificate within 2 months following the end of the Processing Period, the Subscriber must in any case pay the Success Fee.
<br><br><br>
7. Pricing<br><br>
1. The pruces sent by e-mail by the Company to the Subscriber are valid for a period of 30 days following the notification of said e-mail. After this period of validity, these Rates are valid only if they do not deviate from the rates indicated on the website of www.jobtimise.com or www.jobtmise.com. <br>
2. The prices indicated are exclusive of tax, unless explicitly stated as including all taxes. <br>
3. The Company expressly reserves the right to change its rates at any time during the year. However, upon subscription to a Company service, i.e., upon receipt of payment(s), the rates may no longer be changed by the Company and the Company may not retroactively charge an additional amount for any reason whatsoever. <br>
4. The prices indicated on the advertising materials are not contractual and are not binding on the Company.

<br><br><br>
8. Payment method<br><br>
The Subscriber has the option to pay the Company by online payment or by bank transfer only. 
<br><br><br>
9. Spreading of payments<br><br>
The Subscriber may contact the Company's administration by email to request a staggered or deferred payment. The Company is free to accept or refuse such a request.  
<br><br><br>
10. Payment through the use of public aid<br><br>
In case of full payment of the training courses via public subsidies, the training courses cannot be separated into a Fixed Amount and a Success Bonus. The client pays the total amount via the obtained subsidies or by supplementing them. Except in the case of an express written agreement by the Company, payment is not conditional on the success of the support. 
<br><br><br>
11. Financing of training by a legal entity
In case of full payment of the trainings via a legal entity (company, association, foundation), the trainings cannot be separated in the form of a fixed amount and a success bonus. The client pays the totality via the obtained subsidies, but has the possibility to ask for a spread of these payments. Except in the case of an express written agreement of the Company, the payment is not subordinated to the success of the support. 
<br><br><br>
12. Withdrawal period<br><br>
The Subscriber has a period of seven (7) clear days after payment of the registration to exercise his or her right of withdrawal, and the fees paid will be reimbursed in full without the Subscriber having to justify the reasons for the withdrawal. <br>
The seven (7) day right of withdrawal cannot be exercised if the performance of the Contract has already begun.
<br><br><br>
13. Conditions of cancellation<br><br>
Any registration that has become final may be cancelled at no cost, provided that no services have already been provided by the Company (including participation in group or individual training sessions, access to eLearning modules, the sending of "Road Books", coaching sessions, telephone exchanges with the trainers). <br>
From the Company to the Subscriber, the Fixed Fee is due notwithstanding the portion of services actually provided. However, in case of termination due to force majeure or validated in advance by the Company's administration, the reimbursement will be made on a pro rata basis of the services already provided. <br>
Even in the event of termination, the Success Fee shall be due if the elements of successful support provided for in Art. 6 of these General Terms and Conditions of Services have been fulfilled within 30 days of the termination of the Agreement. 
<br><br><br>
14. Subscriber's duty to inform <br> <br>
The Subscriber undertakes to provide all the information necessary for the proper performance of the Contract by the Company. In particular, the Subscriber undertakes to inform the Company without delay of any event constituting successful support. 
<br><br><br>
15. Personal data<br> <br>
By accepting the Contract, the Subscriber consents to the Company collecting, on a legal basis, personal data concerning him/her necessary for the performance of the Contract, such as: name, address, telephone number, e-mail address, Curriculum Vitae, professional status.<br>
The Company undertakes not to communicate this information to third party companies unless the Subscriber expressly agrees. 
<br><br><br>
16. Educational material <br><br>
Unless otherwise provided, any equipment made available to Subscriber in connection with services performed by the Company shall remain the property of the Company.
<br><br><br>
17. Intellectual Property<br><br>
All elements relating to the content of the services, and relating to the Company's website are and remain the exclusive intellectual property of the Company. It is forbidden to reproduce, exploit, redistribute or use them for any purpose whatsoever, even partially and in any form whatsoever, without the explicit agreement of the Company.
<br><br><br>
18. Rules of good conduct<br><br>
The Subscriber undertakes to respect the instructions of the company's staff, consultants, trainers, coaches, the premises and equipment made available to them physically or online, as well as the timetable. Any serious or repeated failure to comply with these requirements may result in the cancellation of the Contract.
<br><br><br>
19. Company's duty of care<br><br>
The Company is responsible to the Subscriber for the proper and faithful performance of the Agreement. The Company shall use its best efforts to assist the Subscriber in achieving the purpose of the Agreement in accordance with the chosen formula.<br>
While the Company will use its best efforts as reasonably required, no result is guaranteed by the Company.
<br><br><br>
20. Exchange and availability of consultants <br><br>
The configuration of Jobtimise coaching and the use of digital tools is aimed at maximum responsiveness. Seizing opportunities is imperative, and this with the sound advice of Jobtimise consultants. Our clients must make the most of this assistance and of the many tools at their disposal.
<br><br><br>
21. Safeguard clause <br><br>
If any provision of the General Terms of Service is or becomes invalid or if the General Terms of Service are incomplete, the remaining provisions of the Agreement shall remain valid. The invalid provision shall be replaced by a provision which then takes effect in the sense and purpose of the invalid provision with legal effect. The same rule applies to all contractual gaps.
<br><br><br>
22. Legal recourse / Jurisdiction<br><br>
The legal relationship between the Company and the Subscriber is governed by Swiss law unless a mandatory rule of law provides otherwise. Unless mandatory law provides otherwise, the parties agree that the court at the Company's registered office shall have exclusive jurisdiction to hear any dispute. However, the Company shall be entitled to bring an action against the Buyer before the court of the latter's registered office.
<br><br><br>
23. Effects after the end of the contractual relationship <br><br>
Notwithstanding the termination of the contractual relationship, the provisions of Articles 15, 16 and 17 shall remain in force in accordance with their terms.`