export const devPlans = [
    { 
      countries:['fr'],
      text:'Coaching complet - 2050€',price:2050,id:1,
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1HTq2MAappgCDnXwgH8VqlZK',
          three_months: 'price_1NcoraAappgCDnXwfTu1DWct',
          five_months: 'price_1HTodAAappgCDnXw826TDdlV'
        },
        successRate: 0.6,
        success:{
          default:'price_1HTodAAappgCDnXwLownAjol',
          three_months:'price_1HTodAAappgCDnXw826TDdlV',
          five_months:'price_1HTqWhAappgCDnXwS18pO1Vs'
        }
      },
      journey: '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb'
    },
    {
      countries:['fr'],
      text:'Bilan + Coaching complet - 2450€',price:2450,id:2,
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1HX2hxAappgCDnXwT2okdHgJ',
          three_months: '',
          five_months: 'price_1HX2hyAappgCDnXwCxYJo8rt'
        },
        successRate: 0.6,
        success:{
          default:'price_1HX2hyAappgCDnXwynFkRpoL',
          three_months:'',
          five_months:'price_1HX2hyAappgCDnXw3Kt7qZAa'
        }
      },
      journey: '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb'
    },
    {
      countries:['fr'],
      text:'Bilan intensif - 1490€',price:1490,id:4,
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1Ho4riAappgCDnXw7LlsmCuU',
          three_months: '',
          five_months: 'price_1Ho4riAappgCDnXwt7i7eOMY'
        }
      },
      journey: '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb'
    },
    { 
      countries:['ch'],
      text:'Coaching complet - 2450 CHF',price:2450, currency:"CHF",id:"1-swiss",
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1JUCkNAappgCDnXwWWFtl0Lx',
          three_months: '',
          five_months: 'price_1JUCkNAappgCDnXwOLjqL8Ji'
        },
        success:{
          default:'price_1JUCkNAappgCDnXwuJURYtIn',
          three_months: '',
          five_months:'price_1JUCkNAappgCDnXwQpKfIUfF'
        }
      },
      journey: '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb'
    },
    {
      countries:['ch'],
      text:'Bilan + Coaching complet - 2850€',price:2850,id:"2-swiss",
      possible_installments: [3,5],
      plans:{
        full:{
          default: 'price_1JUCe0AappgCDnXwMiO25WJk',
          three_months: '',
          five_months: 'price_1JUCefAappgCDnXwv1OA97ie'
        },
        success:{
          default:'price_1JUCgcAappgCDnXwWk6XXkV3',
          three_months:'',
          five_months:'price_1JUCgvAappgCDnXwHQNowUaE'
        }
      },
      journey: '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb'
    },
    {
      countries:['ch'],
      text:'Outils et entretiens - 1000€',price:1000,id:"4-swiss",
      possible_installments: [3],
      plans:{
        full:{
          default: 'price_1JUCmCAappgCDnXw1uq3r0Pt',
          three_months: ''
        }
      },
      journey: '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb'
    },
    { 
      countries:['en'],
      text:'Full coaching - 2050 $US',price:2050, currency: '$US',id:"1-en",
      possible_installments: [5],
      plans:{
        full:{
          default: 'price_1HTq2MAappgCDnXwgH8VqlZK',
          five_months: 'price_1HTodAAappgCDnXw826TDdlV'
        },
        success:{
          default:'price_1HTodAAappgCDnXwLownAjol',
          five_months:'price_1HTqWhAappgCDnXwS18pO1Vs'
        }
      },
      journey: '62da95ceff2ad3cd1272d9b9 63877d9f1965e2ae4a4cb9eb'
    },
    {countries:['fr'],
      text:'E-learning MachinaJOB',id:5
    },
    {countries:['fr'],
      text:'Un atelier thématique',id:3
    }
  ];

export const devWorkshops = {
    interview :[
      {
        countries: ['fr'],
        text:`1h de préparation flash pour un entretien à venir - 149€ `,price:149,id:1,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HUB1tAappgCDnXwsTBJp8AD'
          }
        }
      },
      {
        countries: ['fr'],
        text:`3h d'intense préparation - 330€ `,price: 330,id:2,
        possible_installments: [2],
        plans:{
          full:{
            default: 'price_1HX2K4AappgCDnXwwheD0b9j',
            two_months: ''
          }
        }
      },
      {
        countries: ['fr'],
        text:`5h des méthodes + 3 simulations - 660€`,price: 660,id:3,
        possible_installments: [3],
        plans:{
          full:{
            default: 'price_1HX2MjAappgCDnXwmAbESvSV',
            three_months:'price_1HX2MjAappgCDnXwMH0BHSKU'
          }
        }
      }
    ],
    network:[
      {
        countries:['fr'],
        text:`3h de coaching + travail personnel encadré - 330€`,price: 330,id:1,
        possible_installments: [2],
        plans:{
          full:{
            default: 'price_1HX2PqAappgCDnXwQSWMgHGd',
            two_months:''
          }
        }
      }, 
      {
        countries:['fr'],
        text:`6h de méthodes + refonte des outils et compte LinkedIn - 550€`,price: 550,id:2,
        possible_installments: [3,5],
        plans:{
          full:{
            default: 'price_1HX2SaAappgCDnXwFPuVEEak',
            three_months:'price_1HX2SbAappgCDnXwVEOPbkKx',
            five_months:'price_1HX2SaAappgCDnXw3sTJBAJp'
          }
        }
      }            
    ],
    tools:[
      {
        countries:['fr'],
        text:`1h pour revoir le CV vite et bien - 149€`,price: 149,id:1,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HX2X2AappgCDnXw6ql1z9Uh'
          }
        }
      },  
      {
        countries:['fr'],
        text:`3h pour tout faire ensemble - 330€`,price: 330,id:2,
        possible_installments: [2],
        plans:{
          full:{
            default: 'price_1HX2Z2AappgCDnXwmbaCG5Jy',
            two_months:''
          }
        }
      }         
    ],
    assessment:[
      {
        countries:['fr'],
        text:`1h pour réfléchir à vos choix de carrière - 149€`,price: 149,id:1,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HX2bSAappgCDnXwxLxuRSJq'
          }
        }
      },
      {countries:['fr'],
        text:`2h pour faire le bilan complet - 250€`,price: 250,id:2,
        possible_installments: [],
        plans:{
          full:{
            default: 'price_1HX2dMAappgCDnXw83xJPHs8'
          }
        }
      }   
    ]
  }

export const devElearning = [
    {countries: ['fr'],
      text:`MyJobAcademy illimité - 449€`,price:490,id:1,
      possible_installments: [],
      plans:{
        full:{
          default: 'price_1JtTYlAappgCDnXw8AzjfNSH'
        }
      }
    },
    {countries: ['fr'],
      text:`MyJobAcademy illimité + 2h de coaching - 660€`,price:660,id:2,
      possible_installments: [],
      plans:{
        full:{
          default: 'price_1JtTaNAappgCDnXwIihOLBdF'
        }
      }
    }
  ]